import V4U from "../../images/vaped4u-logo.png";
import Footer from "../Footer";
import "./styles/Brands.css";
import { useState, useContext,useEffect } from "react";
import BookIcon from "@mui/icons-material/Book";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import Button from "@mui/material/Button";
import Wrapper from "./V4ULabels/Wrapper";
import V4USearch from "./V4ULabels/V4USearch";
import UserContext from "../context/userContext";
import { useNavigate } from "react-router-dom";
import StoreNav from "./StoreNav";

export default function Vaped4U() {
  const [showLabel, setShowLabel] = useState(false);
  const [showPick, setShowPick] = useState(false);
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const [site, setSite] = useState("")

  useEffect(() => {
    const url2 = new URL(window.location.href);
    var s = url2.searchParams.get("site");
    setSite(s)
  }, [])

  function handleLabel() {
    if (showLabel === false) {
      setShowLabel(true);
    } else setShowLabel(false);
  }

  function handlePick() {
    if (showPick === false) {
      setShowPick(true);
    } else setShowPick(false);
  }

  return (
    <>
      {/* {userData.user &&
      (userData.user.role === "Manager" || userData.user.role === "Admin") ? ( */}
      <div className="brand">
        <StoreNav site={site} />
        <div className="brandsContent">
          <img src={V4U} alt="Vaped4U Logo" />
          <div className="labelGen">
            <Wrapper>
              <V4USearch />
            </Wrapper>
          </div>
          {/* <Button
              variant="outlined"
              onClick={() => handleLabel()}
              startIcon={<BookIcon color="primary"/>}
            >
              Label Generator
            </Button>

            {showLabel ? (
              <div className="labelGen">
                <Wrapper>
                  <V4USearch />
                </Wrapper>
              </div>
            ) : (
              <></>
            )}
            <Button
              variant="outlined"
              onClick={() => handlePick()}
              startIcon={<PlaylistAddCheckIcon color="primary"/>}
            >
              Pick List
            </Button>

            {showPick ? (
              <div className="pickList">
                <VapedPick />
              </div>
            ) : (
              <></>
            )} */}
        </div>
        <Footer />
      </div>
      {/* ) : (
         <>{navigate("/")}</>
      )} */}
    </>
  );
}
