//React
import { useState, useEffect } from "react";
//Utils
import axios from "axios";
//MUI
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import PurchaseOrderHistoric from "./PurchaseOrderHistoric";
//Styling
const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 600px)": {
        width: "95vw",
    },
};
//Interfaces
interface Props {
    setOrdersModal: any;
}

export default function OrderHistoryModal({ setOrdersModal }: Props) {
    //Main
    const url = process.env.REACT_APP_DEPPLOYED;
    const siteName = localStorage.getItem("site")?.replaceAll('"', "");
    //Orders
    const [lastFiveOrders, setLastFiveOrders] = useState([]);
    const [reload, setReload] = useState<boolean>(false);

    useEffect(() => {
        let getSiteId = localStorage.getItem("siteID");
        if (getSiteId) {
            let _siteId: number = parseInt(getSiteId);
            axios
                .get(`${url}/purchaseOrder/GetAll`)
                .then(async (res) => {
                    console.log(res);
                    let _storeFiltered: any = res.data.filter(
                        (order: any, i: number) => {
                            return order.siteId === _siteId;
                        }
                    );
                    if (_storeFiltered.length > 5) {
                        let fiveLimit = _storeFiltered.slice(0, 5);
                        setLastFiveOrders(fiveLimit);
                    } else {
                        setLastFiveOrders(_storeFiltered);
                    }
                    console.log(_storeFiltered);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [reload]);

    const rel = () => {
        setReload(!reload);
    };

    return (
        <Box sx={style} className="order_history_modal">
            <h1>{siteName && siteName} PO History (Last 5)</h1>
            {lastFiveOrders.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Date/Time</th>
                            <th>User</th>
                            <th>Supplier</th>
                            <th>Status</th>
                            <th>Stock Take ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lastFiveOrders.map((order: any, i: number) => {
                            return (
                                <PurchaseOrderHistoric
                                    poData={order}
                                    rel={rel}
                                />
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <h2>No Previous orders</h2>
            )}
            <Button
                variant="contained"
                color="error"
                onClick={() => setOrdersModal(false)}
            >
                Close
            </Button>
        </Box>
    );
}
