import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import StoreHome from "./StoreHome";
import ProductTags from "./ProductTags";
import HoagiesToEpos from "./hoagies/HoagiesToEpos";
import WastageForm from "./WastageForm";
import CostaOrder from "./costa/CostaOrder";
import Footer from "../../Footer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import StoreNav from "../StoreNav";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ErrorBoundary from "../ErrorBoundary";
import WastageV2 from "./wastage/WastageV2";
import CoffeeIcon from "@mui/icons-material/Coffee";
import DiningIcon from "@mui/icons-material/Dining";
import PackagingOrder from "./packaging/PackagingOrder";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import StorePurchaseOrder from "./purchase_order/StorePurchaseOrder";

const ProductDrawer = () => {
    const [invoices, setInvoices] = useState(true);
    const [prodcutTags, setProductTags] = useState(false);
    const [hoagiesProducts, setHoagiesProducts] = useState(false);
    const [wastageForm, setWastageForm] = useState(false);
    const [costaOrder, setCostaOrder] = useState(false);
    const [purchaseOrder, setPurchaseOrder] = useState(false);
    const [packagingOrder, setPackagingOrder] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);

    const drawerMargin = "12vh";
    const mobileDrawerMargin = "6vh";

    const toggleDrawer = () => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setOpenDrawer(!openDrawer);
    };

    const handleChange = (key) => {
        if (key === "Invoices") {
            setInvoices(true);
            setProductTags(false);
            setHoagiesProducts(false);
            setWastageForm(false);
            setCostaOrder(false);
            setPackagingOrder(false);
        } else if (key === "Product Tags") {
            setInvoices(false);
            setProductTags(true);
            setHoagiesProducts(false);
            setWastageForm(false);
            setCostaOrder(false);
            setPackagingOrder(false);
        } else if (key === "Hoagies Products") {
            setInvoices(false);
            setProductTags(false);
            setHoagiesProducts(true);
            setWastageForm(false);
            setCostaOrder(false);
            setPackagingOrder(false);
        } else if (key === "Wastage Form") {
            setInvoices(false);
            setProductTags(false);
            setHoagiesProducts(false);
            setWastageForm(true);
            setCostaOrder(false);
            setPackagingOrder(false);
        } else if (key === "Costa Order") {
            setInvoices(false);
            setProductTags(false);
            setHoagiesProducts(false);
            setWastageForm(false);
            setCostaOrder(true);
            setPackagingOrder(false);
        } else if (key === "Packaging") {
            setInvoices(false);
            setProductTags(false);
            setHoagiesProducts(false);
            setWastageForm(false);
            setCostaOrder(false);
            setPackagingOrder(true);
        } else if (key === "Purchase Order") {
            setInvoices(false);
            setProductTags(false);
            setHoagiesProducts(false);
            setWastageForm(false);
            setCostaOrder(false);
            setPackagingOrder(false);
            setPurchaseOrder(true);
        }
    };

    return (
        <>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        padding: "0",
                    }}
                >
                    <Toolbar
                        sx={{ width: "100vw", backgroundColor: "#2c53a0" }}
                    >
                        <StoreNav />
                    </Toolbar>
                </AppBar>
                <Button
                    endIcon={<ChevronRightIcon />}
                    onClick={toggleDrawer(true)}
                    sx={{
                        backgroundColor: "#fff",
                        height: "5vh",
                        marginTop: "22vh",
                        position: "absolute",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        ["@media(max-width: 650px)"]: {
                            marginTop: "14vh",
                        },
                        ["@media(min-width: 650px) and (max-width: 700px) "]: {
                            marginTop: "11vh",
                        },
                        ["@media(min-width: 700px) and (max-width: 800px) "]: {
                            marginTop: "13vh",
                        },
                        ["@media(min-width: 800px) and (max-width: 1050px) "]: {
                            marginTop: "17vh",
                        },
                    }}
                >
                    Menu
                </Button>
                <SwipeableDrawer
                    open={openDrawer}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <Toolbar
                        sx={{
                            marginTop: drawerMargin,
                            ["@media(max-width: 600px)"]: {
                                marginTop: mobileDrawerMargin,
                            },
                            ["@media(min-width: 600px) and (max-width: 800px) "]:
                                {
                                    marginTop: "7vh",
                                },
                        }}
                    />
                    <Box
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <List>
                            <ListItem
                                disablePadding
                                onClick={() => handleChange("Invoices")}
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <ReceiptIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Invoices"} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem
                                disablePadding
                                onClick={() => handleChange("Product Tags")}
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <LocalOfferIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Product Tags"} />
                                </ListItemButton>
                            </ListItem>
                            {/* <ListItem
                                disablePadding
                                onClick={() => handleChange("Hoagies Products")}
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <LunchDiningIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={"Hoagies Products"}
                                    />
                                </ListItemButton>
                            </ListItem> */}
                            <ListItem
                                disablePadding
                                onClick={() => handleChange("Wastage Form")}
                                // onClick={() => navigate("/storezone/wastage")}
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <DeleteSweepIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Wastage Form"} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem
                                disablePadding
                                onClick={() => handleChange("Costa Order")}
                                // onClick={() => navigate("/storezone/wastage")}
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <CoffeeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Costa Order"} />
                                </ListItemButton>
                            </ListItem>
                            {/* <ListItem
                                disablePadding
                                onClick={() => handleChange("Packaging")}
                                // onClick={() => navigate("/storezone/wastage")}
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <DiningIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Packaging"} />
                                </ListItemButton>
                            </ListItem> */}
                            <ListItem
                                disablePadding
                                onClick={() => handleChange("Purchase Order")}
                                // onClick={() => navigate("/storezone/wastage")}
                            >
                                <ListItemButton>
                                    <ListItemIcon>
                                        <ShoppingBasketIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={"Auto Ordering"} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Box>
                </SwipeableDrawer>

                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, marginTop: "18vh" }}
                >
                    {invoices ? (
                        <ErrorBoundary>
                            <StoreHome />
                        </ErrorBoundary>
                    ) : prodcutTags ? (
                        <ErrorBoundary>
                            <ProductTags />
                        </ErrorBoundary>
                    ) : hoagiesProducts ? (
                        <ErrorBoundary>
                            <HoagiesToEpos />
                        </ErrorBoundary>
                    ) : wastageForm ? (
                        <ErrorBoundary>
                            {/* <WastageForm /> */}
                            <WastageV2 />
                        </ErrorBoundary>
                    ) : costaOrder ? (
                        <ErrorBoundary>
                            <CostaOrder />
                        </ErrorBoundary>
                    ) : packagingOrder ? (
                        <ErrorBoundary>
                            <PackagingOrder />
                        </ErrorBoundary>
                    ) : purchaseOrder ? (
                        <ErrorBoundary>
                            <StorePurchaseOrder />
                        </ErrorBoundary>
                    ) : (
                        <h1>You don't have permissions for this</h1>
                    )}
                </Box>
            </Box>
            <Footer />
        </>
    );
};

export default ProductDrawer;
