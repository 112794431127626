import Footer from "../../Footer";
import "../styles/productTags.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import StoreNav from "../StoreNav";
import Button from "@mui/material/Button";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export default function ProductTags() {

    const [site, setSite] = useState("");
    const [products, setProducts] = useState([]);
    const [prodName, setProdName] = useState("");
    const [style, setStyle] = useState();

    useEffect(() => {
        let getSite = localStorage.getItem("site");
        let localSite = getSite.replace(/['"]+/g, "");
        setSite(localSite);
    }, [])

    const updateDetails = async (type, change) => {
        if (type == "name") {
            setProdName(change);
        }

        if (type == "style") {
            setStyle(change);
        }
    }

    const addToList = async () => {

        console.log(prodName)
        console.log(style)

        var canAdd = true;

        if (prodName == "") {
            canAdd = false;
        }

        if (style == `--Select a Style--` || style == undefined) {
            canAdd = false;
        }

        if (canAdd) {
            setProducts((prod) => [
                ...prod,
                {
                    item: prodName,
                    style: style
                }
            ]);

            setProdName("");
            setStyle("--Select a Style--");

            document.getElementById("prod_input").value = "";
            document.getElementById("prod_select").value = "--Select a Style--";

            console.log("ADDED")
        } else {
            console.log("Not Added")

        }
    }

    const request = async () => {

        const obj = {
            site: site,
            cards: products,
        }

        console.log(obj);

        axios
            .post( // https://nitropos.backend.nitrosolutions.co.uk
                `https://nitropos-backend.nitrosolutions.co.uk/labelgen/cards`, obj
            )
            .then((res) => {
                setProducts([]);
                document.getElementById("prod_input").value = "";
                document.getElementById("prod_select").value = "--Select a Style--";
            })
            .catch((err) => {
                console.log(err);
            });



    }

    const handleRemove = async (i) => {

        const removeItem = products.filter((val, index) => index !== i);
        setProducts(removeItem);

    }


    return (
        <section className="productTags">
            {/* <StoreNav site={site} /> */}

            <h1>Request New Product tags</h1>
            
            <p>Please provide all details (Such as price if required)</p>

            <div className="details">
                <input type="text"
                    id="prod_input"
                    placeholder="Insert Name Here"
                    onChange={(e) => updateDetails("name", e.target.value)}
                />

                <select
                    id="prod_select"
                    onChange={(e) => {
                        updateDetails("style", e.target.value)
                        console.log(e.target.value)
                    }
                    }>
                    <option>--Select a Style--</option>
                    <option>Cake</option>
                    <option>Ice Cream</option>
                    <option>Slush</option>
                    <option>Pick n Mix</option>
                    <option>Hoagies</option>
                </select>


            </div>
            <Button
                variant="outlined"
                color="success"
                onClick={() => addToList()}
                id="add_button"
            >
                Add
            </Button>

            <div className="display_details" >
                {products.length > 0 ? (
                    <>
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Style</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {products.map(function (d, i) {
                                    return (
                                        <tr>
                                            <td>{d.item}</td>
                                            <td>{d.style}</td>
                                            <td>
                                                <RemoveCircleOutlineIcon
                                                    fontSize="medium"
                                                    color="primary"
                                                    onClick={() => handleRemove(i)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                        <Button
                            variant="outlined"
                            color="success"
                            onClick={() => request()}
                            style={{ width: "20%", margin: "auto",  }}
                            id="add_button"
                        >
                            Request Product Tags 
                        </Button>

                    </>
                ) : (

                    <h1>Add products</h1>

                )}

            </div>
            {/* <Footer /> */}
        </section>
    )


}