import { useEffect, useState, useContext } from "react";
import axios from "axios";
import DevNav from "../nav/DevNav";
import Footer from "../../Footer";
import "../devZone.scss";

import { useNavigate } from "react-router-dom";

export default function PromotionsHome() {

    const url = process.env.REACT_APP_DEPPLOYED;
    //const url = "http://10.0.0.34:4022";

    const navigate = useNavigate();
    const [promotions, setPromotions] = useState([]);

    useEffect(async () => {

        axios.get(`${url}/promotions/promotions`)
            .then((res) => {
                setPromotions(res.data);
            }).catch((err) => {
                console.log(err)
            })


    }, []);


    const handlePromotion = (promoID) => {
        // localStorage.setItem("promos", JSON.stringify(promoID));
        navigate(`/dev-zone/promos/${promoID}`);
    };


    return (
        <section className="devZone">
            <DevNav />
            <section className="devzone__main">
                <section className="devzone__promo__buttons">
                    {promotions.map(function (d, i) {
                        return (
                            <button
                                className="promo__button"
                                role="button"
                                onClick={() => handlePromotion(d.promotionId)}
                            >
                                <span >{d.name}</span>
                                <span className="text">
                                    {new Date(d.sellingDateStart).toLocaleDateString()} - {new Date(d.sellingDateEnd).toLocaleDateString()}
                                </span>

                            </button>
                        );
                    })}

                </section>
            </section>
            <Footer />
        </section>
    )

}