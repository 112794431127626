import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import PrintModal from "./PrintModal";
import { Modal } from "@mui/material";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "70vh",
};

export default function Publish({
    user,
    rotaId,
    setPublishModal,
    published,
    reloadData,
    staffList,
    currentDate,
}) {
    //Main - Admin
    const hrUrl = process.env.REACT_APP_API_URL;
    // const hrUrl = "http://10.0.0.34:4030";
    const [hasShifts, setHasShifts] = useState(false);

    //Alerts
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");

    //Print modal
    const [printModal, setPrintModal] = useState(false);

    //Check the rota has shift - Publishing rota dependant on rota having shifts
    useEffect(() => {
        let gotShifts = staffList.some((staffMember) => {
            return staffMember.worked.some((shifts) => shifts.shiftId);
        });

        gotShifts && setHasShifts(true);
    }, []);

    //Alert function
    const showAlert = (message, severity, duration = 3000) => {
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setSeverity("");
        }, duration);
    };

    //Publish Rota
    const handlePublish = () => {
        let data = {
            user: user,
            rotaId: rotaId,
        };

        if (!hasShifts) {
            showAlert("Unable to publish as rota has no shifts", "warning");
        } else {
            axios
                .post(`${hrUrl}/rota/publish`, data)
                .then((res) => {
                    showAlert("Rota had been published", "success");
                    reloadData();
                    setPrintModal(true);
                })
                .catch((err) => {
                    console.log(err);
                    showAlert(
                        "Something has went wrong - Please try again",
                        "error"
                    );
                });
        }
    };

    // Republish full Rota
    const handleRepublish = () => {
        let data = {
            user: user,
            rotaId: rotaId,
        };

        if (!hasShifts) {
            showAlert("Unable to publish as rota has no shifts", "warning");
        } else {
            axios
                .post(`${hrUrl}/rota/republish`, data)
                .then((res) => {
                    showAlert("Rota had been published", "success");
                    reloadData();
                    setPrintModal(true);
                })
                .catch((err) => {
                    console.log(err);
                    showAlert(
                        "Something has went wrong - Please try again",
                        "error"
                    );
                });
        }
    };
    return (
        <Box sx={style} className="publish_modal">
            <Typography id="modal-modal-title" variant="h6" component="h2">
                {!published ? "Publish Check" : "Republish Check"}
            </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                You are about to {!published ? "publish" : "republish"} this
                rota. All employees on the rota will receive an email with their
                shifts, so please ensure that all entered shifts are accurate
                before proceeding. If you are happy to proceed click confirm.T
            </Typography>

            <Typography className="publish_btns">
                {!alert ? (
                    <>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => setPublishModal(false)}
                        >
                            close
                        </Button>
                        {!published ? (
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handlePublish}
                            >
                                Confirm
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleRepublish}
                            >
                                Confirm
                            </Button>
                        )}
                    </>
                ) : (
                    <Alert sx={{ width: "100%" }} severity={severity}>
                        {message}
                    </Alert>
                )}
            </Typography>
            <Modal open={printModal}>
                <PrintModal
                    rotaId={rotaId}
                    handleClosePrint={setPrintModal}
                    setPublishModal={setPublishModal}
                    currentDate={currentDate}
                />
            </Modal>
        </Box>
    );
}
