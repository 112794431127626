import { useState, useEffect } from "react";
import Loader from "../../animations/Loader";
import axios from "axios";
import "./duplicates/dupe.scss";
import WareNav from "../../warehouse/WareNav";
import ManagerNav from "../ManagerNav";
import Footer from "../../Footer";
import ProductDetails from "./products/tabs/ProductDetails";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "75vw",
    maxHeight: "55vh",
    bgcolor: "#e4ffff",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
    "@media(max-width: 600px)": {
        width: "90vw",
        maxHeight: "70vh",
    },
};

export default function NoSupplier() {
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [role, setRole] = useState("");
    const [product, setProduct] = useState("");
    const [categoryList, setCategoryList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [reload, setReload] = useState(true);

    const [showDetails, setShowDetails] = useState(false);

    const handleOpenDetails = () => setShowDetails(true);
    const handleCloseDetails = () => setShowDetails(false);

    useEffect(() => {
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        setRole(user.user.role);

        axios
            .get(
                `https://nitropos-backend.nitrosolutions.co.uk/eposProducts/products/no-supplier`
            )
            .then((res) => {
                setItems(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(
                "https://nitropos-backend.nitrosolutions.co.uk/eposSQL/getSuppliers"
            )
            .then((res) => {
                setSupplierList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        axios
            .get(
                "https://nitropos-backend.nitrosolutions.co.uk/eposSQL/getCategories"
            )
            .then((res) => {
                setCategoryList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [reload]);

    const rel = () => {
        handleCloseDetails();
        setReload(!reload);
    };

    const selectProduct = (product) => {
        setProduct(product);
        handleOpenDetails();
    };

    return (
        <main>
            {role === "V4U Manager" ? <WareNav /> : <ManagerNav />}
            <section id="dupeTable">
                <h1>No Suppliers</h1>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        {items.length > 0 ? (
                            <>
                                <h2>FOUND {items.length} PRODUCTS</h2>
                                <table>
                                    <thead>
                                        <th>Name</th>
                                        <th>Barcode</th>
                                        <th>Cost Price</th>
                                        <th>Sale Price</th>
                                        <th>Edit</th>
                                    </thead>
                                    <tbody>
                                        {items.map(function (p, i) {
                                            return (
                                                <tr>
                                                    <td>{p.name}</td>
                                                    <td>{p.barcode}</td>
                                                    <td>
                                                        £
                                                        {parseFloat(
                                                            p.costPrice
                                                        ).toFixed(2)}
                                                    </td>
                                                    <td>
                                                        £
                                                        {parseFloat(
                                                            p.salePrice
                                                        ).toFixed(2)}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <EditIcon
                                                            fontSize="small"
                                                            onClick={() =>
                                                                selectProduct(p)
                                                            }
                                                            sx={{
                                                                cursor: "pointer",
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <h2>No Products Found</h2>
                        )}
                    </>
                )}
                <Modal
                    open={showDetails}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    onClose={handleCloseDetails}
                >
                    <Box sx={style}>
                        <ProductDetails
                            product={product}
                            rel={rel}
                            categories={categoryList}
                            suppliers={supplierList}
                        />
                    </Box>
                </Modal>
            </section>
            <Footer />
        </main>
    );
}
