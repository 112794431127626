//React
import { useEffect, useState } from "react";
import axios from "axios";
//MUI
import { Modal } from "@mui/material";
import { Alert } from "@mui/material";
//Styling
import "./styles/fuelSales.scss";
//Components
import SuspendedModal from "./SuspendedModal";
import Loader from "../animations/Loader";

export default function Suspended() {
    //URL
    // const url = "http://10.0.0.34:4022";
    const url = process.env.REACT_APP_DEPPLOYED;
    //Site Data
    const [suspendedData, setSuspendData] = useState([]);
    const [selectedSite, setSelectedSite] = useState({});
    //Modal
    const [openModal, setOpenModal] = useState(false);
    //Loader & Alert
    const [loader, setLoader] = useState(false);
    const [alert, setAlert] = useState(false);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        setLoader(true);
        //TODO: BRING IN SUSPENDED FUEL
        axios
            .get(`${url}/fuel/SuspendedTransactions`)
            .then((res) => {
                setSuspendData(res.data);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
                setAlert(true);
            });
        //Reload data every 60 seconds
        setTimeout(() => {
            setReload(!reload);
        }, 60000);
    }, [reload]);
    return (
        <main className="suspended_fuel">
            {!loader ? (
                !alert ? (
                    <>
                        {/* //TODO: MAPS SITES */}
                        {suspendedData &&
                            suspendedData.map((site, i) => {
                                return (
                                    <div
                                        className="suspend_card"
                                        onClick={() => {
                                            setSelectedSite(site);
                                            setOpenModal(true);
                                        }}
                                        key={i}
                                    >
                                        <h2>{site.siteName}</h2>
                                        <h3>{site.activeSuspended}</h3>
                                    </div>
                                );
                            })}
                    </>
                ) : (
                    <Alert severity="error">
                        Sorry we can't get what you need - Please try again
                    </Alert>
                )
            ) : (
                <Loader />
            )}
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <SuspendedModal selectedSite={selectedSite} />
            </Modal>
        </main>
    );
}
