import StoreNav from "../../StoreNav";
import axios from "axios";
import { useState, useEffect } from "react";
import Footer from "../../../Footer";
import "./hoagies.scss";
import IndvHoagiesProducts from "./IndvHoagiesProduct";
import { Button } from "@mui/material";
import Tick from "../../../animations/Tick";
import Alert from "@mui/material/Alert";

export default function HoagiesToEpos() {
  const [site, setSite] = useState("");
  const [hoagiesItems, setHoagiesItems] = useState([]);
  const [ingCat, setIngCat] = useState([]);
  //alerts
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showTick, setShowTick] = useState(false);

  const url = "https://nitropos-backend.nitrosolutions.co.uk";

  useEffect(() => {
    //gets the site
    let s = localStorage.getItem("siteID");
    if (!s) {
      s = localStorage.getItem("site").replace(/['"]+/g, "");
    }
    setSite(s);
    //searches all the hoagies items from Hoagies DB
    axios
      .get("https://backend.hoagies.co.uk/food/epos/products/all")
      .then((res) => {
        setIngCat(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //search of product on the datalist
  const searchProduct = (item) => {
    if (item.length > 3) {
      ingCat.forEach((product) => {
        if (product.eposId == item) {
          //if product already exists alerts and doesn't add it to the array
          let findProduct = hoagiesItems.findIndex(
            (a) => a.productCode == item
          );
          if (findProduct < 0) {
            setHoagiesItems([
              ...hoagiesItems,
              { name: product.name, productCode: product.eposId },
            ]);
          } else {
            document.getElementById("hoagies_epos_btn").style.display = "none";
            setMessage("Yop cannot add this product again");
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              setMessage("");
              setSeverity("");
              document.getElementById("hoagies_epos_btn").style.display =
                "flex";
            }, 3000);
          }
        }
      });
    }
  };

  //adds qty to the products in the array
  const handleQtyChange = (qty, index) => {
    if (qty < 0) {
      document.getElementById("hoagies_epos_btn").style.display = "none";
      setMessage("Quantities cannot be smaller than zero");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
        setSeverity("");
        let inputField = document.getElementById(index);
        inputField.value = 0;
        document.getElementById("hoagies_epos_btn").style.display = "flex";
      }, 3000);
    } else {
      hoagiesItems[index].toStock = qty;
    }
  };

  //removed selected item from the table
  const handleRemove = (item) => {
    let array = hoagiesItems;
    let filterArray = array.filter((a) => a.productCode != item);
    setHoagiesItems(filterArray);
  };

  const handleSubmitStock = () => {
    document.getElementById("hoagies_epos_btn").style.display = "none";

    if (hoagiesItems.length === 0) {
      setMessage("Please select items to submit");
      setSeverity("warning");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("hoagies_epos_btn").style.display = "flex";
      }, 3000);
    } else {
      for (let i = 0; i < hoagiesItems.length; i++) {
        //checks that all the products have a qty
        if (!hoagiesItems[i].toStock || hoagiesItems[i].toStock == 0) {
          setMessage("All items must have a quantity");
          setSeverity("warning");
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setMessage("");
            setSeverity("");
            document.getElementById("hoagies_epos_btn").style.display = "flex";
          }, 3000);
          break;
        } else {
          const data = {
            site: site,
            items: hoagiesItems,
          };
          axios
            .post(`${url}/stock/addProductStock`, data)
            .then((res) => {
              console.log(res);
              console.log(res.data);
              if (res.status === 200) {
                setShowTick(true);
                setTimeout(() => {
                  setShowTick(false);
                  setHoagiesItems([]);
                  document.getElementById("hoagies_epos_btn").style.display =
                    "flex";
                }, 3000);
              } else {
                setMessage("Something went wrong, please try again");
                setSeverity("error");
                setShowAlert(true);
                setTimeout(() => {
                  setShowAlert(false);
                  setMessage("");
                  setSeverity("");
                  document.getElementById("hoagies_epos_btn").style.display =
                    "flex";
                }, 3000);
              }
            })
            .catch((err) => {
              console.log(err);
              setMessage("Something went wrong, please try again");
              setSeverity("error");
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
                setMessage("");
                setSeverity("");
                document.getElementById("hoagies_epos_btn").style.display =
                  "flex";
              }, 3000);
            });
        }
      }
    }
  };

  return (
    <main>
      {/* <StoreNav /> */}
      <section className="hoagies__epos">
        <h1>Add Hoagies Stock</h1>
        <form>
          <label>Select Products:</label>
          <input
            list="products"
            onChange={(e) => searchProduct(e.target.value)}
            placeholder="Select Product"
            id="search_input"
          />
          <datalist id="products">
            {ingCat.map(function (product, idx) {
              return <option value={product.eposId}>{product.name}</option>;
            })}
          </datalist>
          {hoagiesItems && hoagiesItems.length > 0 ? (
            <table className="hoagies_epos_table">
              <thead>
                <td>Product</td>
                <td>Quantity</td>
                <td>Delete</td>
              </thead>
              <tbody>
                {hoagiesItems.map(function (item, idx) {
                  return (
                    <IndvHoagiesProducts
                      item={item}
                      index={idx}
                      handleQtyChange={handleQtyChange}
                      handleRemove={handleRemove}
                    />
                  );
                })}
              </tbody>
            </table>
          ) : (
            <></>
          )}
          <Button
            variant="contained"
            color="success"
            onClick={() => handleSubmitStock()}
            id="hoagies_epos_btn"
            sx={{ marginTop: "2%" }}
          >
            Submit
          </Button>
          {showTick ? <Tick /> : <></>}
          {showAlert ? <Alert severity={severity}>{message}</Alert> : <></>}
        </form>
      </section>
      {/* <Footer /> */}
    </main>
  );
}
