import { useState, useEffect, Component } from "react";
import { Navigate } from "react-router-dom";
import "../../styles/tiktok.scss";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import WareNav from "../WareNav";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import ReturnForm from "./ReturnForm";
import OpenReturns from "./OpenReturns";
const drawerWidth = "15vw";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
          <h3>An Alert has been sent to IT</h3>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

const TiktokContainer = () => {
  const [value, setValue] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidthwidth < 690) {
      setIsMobile(true);
    }
  }, [window.innerWidth]);

  const handleChange = (index) => {
    setValue(index + 1);
  };

  return (
    <main className="tiktok_container">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            padding: "0",
          }}
        >
          <Toolbar sx={{ width: "100vw", backgroundColor: "#2c53a0" }}>
            <WareNav />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
            ["@media(max-width: 690px)"]: {
              width: "15vw",
            },
          }}
        >
          <Toolbar />
          <Box
            sx={{
              overflow: "hidden",
              marginTop: "13vh",
              ["@media(min-width: 600px)"]: {
                marginTop: "3vh",
              },
              ["@media(min-width: 600px)"]: {
                marginTop: "5vh",
              },
              ["@media(min-width: 850px)"]: {
                marginTop: "10vh",
              },
              ["@media(min-width: 850px)"]: {
                marginTop: "12vh",
              },
            }}
          >
            <List>
              {["Form", "Open returns"].map((text, index) => (
                <ListItem
                  key={text}
                  disablePadding
                  onClick={() => handleChange(index)}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      {index === 0 ? (
                        <FormatAlignCenterIcon />
                      ) : index === 1 ? (
                        <ImportContactsIcon />
                      ) : (
                        <></>
                      )}
                    </ListItemIcon>
                    {!isMobile ? (
                      <ListItemText
                        primary={text}
                        sx={{
                          display: "none",
                          ["@media(min-width: 850px)"]: {
                            display: "block",
                          },
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            marginTop: "2vh",
            ["@media(max-width:600px)"]: {
              marginTop: "10vh",
            },
            ["@media(min-width:720px)"]: {
              marginTop: "5vh",
            },
            ["@media(min-width:870px)"]: {
              marginTop: "12vh",
            },
          }}
        >
          <Toolbar />
          {value === 1 ? (
            <ErrorBoundary>
              <ReturnForm />
            </ErrorBoundary>
          ) : value === 2 ? (
            <ErrorBoundary>
              <OpenReturns />
            </ErrorBoundary>
          ) : (
            <h1>You don't have permissions for this</h1>
          )}
        </Box>
      </Box>
    </main>
  );
};

export default TiktokContainer;
