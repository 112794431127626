// MAIN IMPORTS
import { useState, useEffect, useRef } from "react";
import WareNav from "../../../warehouse/WareNav";
import ManagerNav from "../../ManagerNav";
import Footer from "../../../Footer";
import FoundProducts from "./tabs/FoundProducts";
import ProductDetails from "./tabs/ProductDetails";
import axios from "axios";
import "./products.scss";
// MUI
import Alert from "@mui/material/Alert";
import { Button } from "@mui/material";
//ICONS
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export default function EposProducts() {
   
    const url = process.env.REACT_APP_DEPPLOYED
    //REFS
    const supplierInputRef = useRef();
    //USER
    const [role, setRole] = useState("");
    const [userName, setUserName] = useState("");
    //PRODUCTS & SUPPLIERS
    const [categoryList, setCategoryList] = useState([]);
    const [supplierList, setSupplierList] = useState([]);
    const [foundProducts, setFoundProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [addProduct, setAddProduct] = useState(false);
    const [productName, setProductName] = useState("");
    const [productBarcode, setProductBarcode] = useState("");
    const [productOrderCode, setProductOrderCode] = useState("");
    const [productSupplier, setProductSupplier] = useState("");
    //ALERTS
    const [noProductsAlert, setNoProductsAlert] = useState(false);
    const [productError, setProductError] = useState(false);

    useEffect(() => {
        //USER
        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        setRole(user.user.role);
        setUserName(user.user.user);

        //PARAM SEARCHES
        const queryParams = new URLSearchParams(window.location.search);
        const b = queryParams.get("barcode");
        const p = queryParams.get("productId");
        b && setProductBarcode(b);
        p && setSelectedProduct(p);

        //BRING IN SUPPLIERS
        axios
            .get(`${url}/eposSQL/getSuppliers`)
            .then((res) => {
                setSupplierList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        //BRING IN FOUND PRODUCTS
        if (b) {
            axios
                .post(`${url}/eposProducts/searchProduct`, { barcode: b })
                .then((res) => {
                    setProductError(false);
                    setSelectedProduct(null);
                    if (res.data.length > 0) {
                        setFoundProducts(res.data);
                        setNoProductsAlert(false);
                    } else {
                        setNoProductsAlert(true);
                        setFoundProducts([]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            axios
                .post(`${url}/eposProducts/searchProduct`, {})
                .then((res) => {
                    setFoundProducts(res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        // BRING IN CATEGORIES
        axios
            .get(`${url}/eposSQL/getCategories`)
            .then((res) => {
                setCategoryList(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    //RESETS SEARCH INPUTS
    const resetSearch = () => {
        setNoProductsAlert(false);
        setSelectedProduct(null);
        setFoundProducts([]);
        setAddProduct(false);
        setProductName("");
        setProductBarcode("");
        setProductOrderCode("");
        setProductSupplier("");
        supplierInputRef.current.value = "Search By Supplier";
    };

    //SEARCH BY NAME / BARCODE / ORDER CODE / SUPPLIER
    const handleSearch = (sup) => {
        setProductError(false);
        setAddProduct(false);

        axios
            .post(`${url}/eposProducts/searchProduct`, {
                name: productName,
                barcode: productBarcode,
                productOrder: productOrderCode,
                supplierId: sup ? sup : productSupplier,
            })
            .then((res) => {
                setSelectedProduct(null);
                if (res.data.length > 0) {
                    setFoundProducts(res.data);
                    setNoProductsAlert(false);
                } else {
                    setNoProductsAlert(true);
                    setFoundProducts([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    //CLEARS SEARCH AND SETS ADD PRODUCT BOOL
    const handleAddProduct = () => {
        setProductError(false);
        resetSearch();
        setAddProduct(true);
    };

    return (
        <main className="epos__products main">
            {role === "V4U Manager" ? <WareNav /> : <ManagerNav />}
            {/* SEARCH PRODUCT SECTION */}
            <section className="epos__search">
                <h1>Eposnow Products</h1>
                {!addProduct && selectedProduct == null ? (
                    <Button
                        id="add_prod_btn"
                        onClick={handleAddProduct}
                        variant="contained"
                        startIcon={<AddCircleIcon id="j" />}
                    >
                        Add
                    </Button>
                ) : null}

                <section className="epos_search">
                    <div className="search_area">
                        <input
                            type="text"
                            value={productName}
                            placeholder="Search by Product Name"
                            onChange={(e) => setProductName(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                        />
                        <Button
                            onClick={() => handleSearch()}
                            color="primary"
                            variant="contained"
                            className="search_btn"
                        >
                            <SearchIcon />
                        </Button>
                    </div>

                    <div className="search_area">
                        <input
                            type="text"
                            value={productBarcode}
                            onChange={(e) => setProductBarcode(e.target.value)}
                            placeholder="Search by Barcode"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                        />
                        <Button
                            onClick={() => handleSearch()}
                            color="primary"
                            variant="contained"
                            className="search_btn"
                        >
                            <SearchIcon />
                        </Button>
                    </div>
                    <div className="search_area">
                        <input
                            type="text"
                            value={productOrderCode}
                            onChange={(e) =>
                                setProductOrderCode(e.target.value)
                            }
                            placeholder="Search by Order Code"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                }
                            }}
                        />
                        <Button
                            onClick={() => handleSearch()}
                            color="primary"
                            variant="contained"
                            className="search_btn"
                        >
                            <SearchIcon />
                        </Button>
                    </div>
                    <div className="search_area">
                        <select
                            onChange={(e) => {
                                setProductSupplier(e.target.value);
                                handleSearch(e.target.value);
                            }}
                            id="supplier__select"
                            ref={supplierInputRef}
                        >
                            <option value="">Search By Supplier</option>
                            {supplierList.map(function (d, i) {
                                return (
                                    <option key={i} value={d.eposId}>
                                        {d.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </section>
                {/* NO PRODUCTS ALERT SECTION */}
                {noProductsAlert ? (
                    <Alert severity="warning" className="no_prod_alert">
                        No products found - click{" "}
                        <span onClick={handleAddProduct}>here</span> to add new
                        product or search again.
                    </Alert>
                ) : null}
                {/* PRODUCT ERROR ALERT */}
                {productError ? (
                    <Alert severity="error" className="no_prod_alert">
                        Something went wrong - Please try again. If the problem
                        persists please contact IT
                    </Alert>
                ) : null}
                {/* FOUND PRODUCTS LIST */}
                {foundProducts.length > 0 &&
                !addProduct &&
                selectedProduct === null ? (
                    <FoundProducts
                        products={foundProducts}
                        setSelectedProduct={setSelectedProduct}
                    />
                ) : null}
                {/* PRODUCT DETAILS */}
                {selectedProduct !== null || addProduct ? (
                    <ProductDetails
                        product={selectedProduct}
                        setSelectedProduct={setSelectedProduct}
                        setProductError={setProductError}
                        setFoundProducts={setFoundProducts}
                        categories={categoryList}
                        suppliers={supplierList}
                        addProduct={addProduct}
                        user={userName}
                    />
                ) : null}
            </section>
            <Footer />
        </main>
    );
}
