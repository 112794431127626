import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Attendance from "./Attendance";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import LightModeIcon from "@mui/icons-material/LightMode";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

import LogsTab from "./LogsTab";
import NotesTab from "./NotesTab";

import "./shiftModal.scss";

import Modal from "@mui/material/Modal";

export default function ShiftModal({
    handleClose,
    rotaId,
    employee,
    _shiftId,
    date,
    site,
    reloadData,
    otherSite,
}) {
    const config = {
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem("auth-token"),
        },
    };

    const hrUrl = process.env.REACT_APP_API_URL;
    // const hrUrl = "http://10.0.0.34:4030";

    const [user, setUser] = useState("");

    const [shiftId, setShiftId] = useState(null);
    const [shiftData,setShiftData] = useState({})

    // Shift Times
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [actualStartTime, setActualStartTime] = useState(null);
    const [actualEndTime, setActualEndTime] = useState(null);

    // Statuses
    const [published, setPublished] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

    // Types
    const [noShow, setNoShow] = useState(false);
    const [nightShift, setNightShift] = useState(false);

    // Times
    const [totalHours, setTotalHours] = useState(null);
    const [hoursWorked, setHoursWorked] = useState(null);
    const [breakTime, setBreakTime] = useState(null);

    const [editBreak, setEditBreak] = useState(false);

    // Alert
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [alertLevel, setAlertLevel] = useState("");

    // Data Change Check
    const [notSaved, setNotSaved] = useState(true);

    // Reload
    const [rel, setReload] = useState(false);

    // Tab Page
    const [tabPage, setTabPage] = useState(0);

    const [logs, setLogs] = useState([]);
    const [notes, setNotes] = useState([]);

    //Attendance Modal
    const [attendanceModal, setAttendanceModal] = useState(false);

    // Delete Confirmation
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const handleConfirmationOpen = () => setConfirmationOpen(true);
    const handleConfirmationClose = () => setConfirmationOpen(false);

    //Close Confirmation
    const [closeConfirmation, setCloseConfirmation] = useState(false);

    const handleOpenConfirmation = () => setCloseConfirmation(true);
    const handleCloseConfirmation = () => setCloseConfirmation(false);

    const [borderColour, setBorderColour] = useState("");

    const [constOpen, setConstOpen] = useState(false);
    const [cannotSave, setCannotSave] = useState(false);

    let style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        bgcolor: "background.paper",
        border: `solid ${borderColour}`,
        borderRadius: "1rem",
        boxShadow: 24,
        p: 4,
        overflowY: "auto",
    };

    useEffect(() => {
        console.log(employee);
        setReload(false);
        setNotSaved(true);
        setNightShift(false);
        setBorderColour("#FF6363");
        setCannotSave(false);

        let currentUser = JSON.parse(localStorage.getItem("userDetails"));
        setUser(currentUser.user.user);

        //Check if Site is 24 Hours
        if (site.toLowerCase().includes("braeside")) {
            setConstOpen(true);
        } else {
            setConstOpen(false);
        }

        if (_shiftId != null || shiftId != null) {
            // Temp var for shift Id
            let currShiftId = null;

            if (_shiftId != null) {
                setShiftId(_shiftId);
                currShiftId = _shiftId;
            } else {
                setShiftId(shiftId);
                currShiftId = shiftId;
            }

            axios
                .get(`${hrUrl}/rota/shift/${currShiftId}`, config)
                .then(async (res) => {
                    const _shift = res.data;
                    console.log(_shift);
                    setShiftData(_shift)

                    let _startTime = await handleDateConvert(_shift.startTime);
                    let _endTime = await handleDateConvert(_shift.endTime);

                    let _breakTime = moment(
                        _shift.breakTime,
                        "HH:mm:ss"
                    ).format("HH:mm");

                    setStartTime(_startTime);
                    setEndTime(_endTime);

                    if (
                        _shift.actualStart !== null &&
                        _shift.actualEnd !== null &&
                        _shift.published
                    ) {
                        handleWorkedHours(
                            await handleDateConvert(_shift.actualStart),
                            await handleDateConvert(_shift.actualEnd),
                            _breakTime,
                            _shift.published
                        );
                    } else if (
                        _shift.startTime !== null &&
                        _shift.endTime !== null
                    ) {
                        handleWorkedHours(
                            _startTime,
                            _endTime,
                            _breakTime,
                            _shift.published
                        );
                    }

                    if (_shift.published) {
                        if (_shift.actualStart !== null) {
                            setActualStartTime(
                                await handleDateConvert(_shift.actualStart)
                            );
                        } else {
                            setActualStartTime(
                                await handleDateConvert(_shift.startTime)
                            );
                        }

                        if (_shift.actualEnd !== null) {
                            setActualEndTime(
                                await handleDateConvert(_shift.actualEnd)
                            );
                        } else {
                            setActualEndTime(
                                await handleDateConvert(_shift.endTime)
                            );
                        }
                    }

                    setPublished(_shift.published);
                    setConfirmed(_shift.confirmed);

                    setNoShow(_shift.noShow);

                    setLogs(_shift.history);

                    setNotes(_shift.notes);

                    //Setting up border colour
                    if (!_shift.published) {
                        setBorderColour("#dfe6f0");
                    } else if (_shift.noShow) {
                        setBorderColour('#f44b48')
                    } else if (_shift.published && !_shift.confirmed) {
                        setBorderColour("#64c4e0");
                    } else if (_shift.confirmed) {
                        setBorderColour("#41f2b1");
                    }
                })
                .catch((err) => { });
        } else {
            setNotes(null);
        }
    }, [rel]);

    const reload = () => {
        setReload(!rel);
    };

    const handleDateConvert = async (date) => {
        return date; //moment(date).format("YYYY-MM-DD HH:mm");
    };

    // ALERT FUNCTION
    const handleModelAlert = async (text, level) => {
        setAlert(true);
        setAlertText(text);
        setAlertLevel(level);

        setTimeout(() => {
            setAlert(false);
            setAlertText("");
            setAlertLevel("");
        }, 5000);
    };

    // SAVE
    const handleSave = async (reload) => {
        setCannotSave(true);
        let data = {
            rotaId: rotaId,
            shiftId: shiftId,
            employeeDetailsId: employee.employeeDetailsId,
            startTime: startTime,
            endTime: endTime,
            actualStart: actualStartTime,
            actualEnd: actualEndTime,
            hoursWorked: hoursWorked,
            breakTime: breakTime,
            user: user,
        };

        let rtnObj = {
            saved: false,
            shiftId: null,
        };

        if (endTime === null || startTime === null) {
            handleModelAlert(
                "Must enter start and end time before saving",
                "error"
            );
            setCannotSave(false);
        } else {
            await axios
                .post(`${hrUrl}/rota/save/new`, data, config)
                .then((response) => {
                    if (response.data.clash) {
                        handleModelAlert(
                            "This clashes with another shift for this employee",
                            "error"
                        );
                    } else {
                        setShiftId(response.data.shiftId);

                        reload && reloadData(employee.employeeDetailsId);
                        rtnObj.saved = true;
                        rtnObj.shiftId = response.data.shiftId;
                    }
                    setNotSaved(false);
                })
                .catch((error) => {
                    console.log(error);
                    setNotSaved(false);
                });
        }

        return rtnObj;
    };

    const handleDelete = async () => {
        setCannotSave(true);
        const data = {
            shiftId: shiftId,
            user: user,
            rotaId: rotaId,
        };

        await axios
            .post(`${hrUrl}/rota/shift/delete`, data, config)
            .then((response) => {
                setCannotSave(false);
                handleConfirmationClose();
                handleClose();
                reloadData();
                return true;
            })
            .catch((error) => {
                setCannotSave(false);
                console.log(error);
                return false;
            });
    };

    const handleConfirm = async () => {
        setCannotSave(true);
        let _shift = await handleSave(false);

        if (_shift.saved) {
            const data = {
                shiftId: _shift.shiftId,
                user: user,
            };

            await axios
                .post(`${hrUrl}/rota/shift/confirm`, data, config)
                .then((response) => {
                    setCannotSave(false);
                    setNotSaved(true);
                    reloadData(employee.employeeDetailsId);
                    return true;
                })
                .catch((error) => {
                    setCannotSave(false);
                    console.log(error);
                    return false;
                });
        }
    };

    const handlePublishStatus = async (publish) => {
        let _shift = await handleSave(false);
        setCannotSave(true);

        if (_shift.saved) {
            const data = {
                shiftId: _shift.shiftId,
                publish: publish,
                user: user,
            };

            await axios
                .post(`${hrUrl}/rota/shift/publish`, data, config)
                .then((response) => {
                    setCannotSave(false);
                    setNotSaved(true);

                    reloadData(employee.employeeDetailsId);
                })
                .catch((error) => {
                    setCannotSave(false);
                    return false;
                });
        }
    };

    //  HANDLES START DATE BEING EDITED / ADJUSTED
    const handleStartDateTime = (time, _endTime, actual) => {
        // Build Start Date Time
        let nStartTime = "";
        if (!actual) {
            nStartTime = date + " " + time;
            nStartTime = moment(nStartTime).format("YYYY-MM-DD HH:mm");
        } else {
            nStartTime = moment(time).format("YYYY-MM-DD HH:mm");
        }

        // If an endTime then check start time is after
        if (_endTime !== null) {
            // MAKE SURE START TIME IS BEFORE END TIME
            if (moment(nStartTime).isBefore(_endTime)) {
                if (!actual) {
                    setStartTime(nStartTime);
                } else {
                    setActualStartTime(nStartTime);
                }
                setNotSaved(false);
                handleWorkedHours(nStartTime, _endTime, breakTime, published);
            } else {
                handleModelAlert(
                    `The Start Time must be before ${moment(_endTime).format(
                        "HH:mm"
                    )}`,
                    "error"
                );
                setStartTime("");
            }
        } else {
            setStartTime(nStartTime);
            setNotSaved(false);
        }
    };

    //  HANDLES END DATE BEING EDITED / ADJUSTED
    const handleEndDateTime = (time, _startTime, actual) => {
        // Build Start Date Time
        let nEndTime = "";
        if (!constOpen) {
            nEndTime = time;
        } else {
            nEndTime = moment(time).format("YYYY-MM-DD HH:mm");
        }

        // If Start Time do check
        if (_startTime !== null) {
            // MAKE SURE END TIME IS AFTER START TIME
            if (moment(nEndTime).isAfter(_startTime)) {
                if (!actual) {
                    setEndTime(nEndTime);
                } else {
                    setActualEndTime(nEndTime);
                }
                setNotSaved(false);
                handleWorkedHours(_startTime, nEndTime, breakTime, published);
            } else {
                handleModelAlert(
                    `The Shift End Time must be after ${moment(
                        _startTime
                    ).format("DD-MM-YYYY HH:mm")}`,
                    "error"
                );
                setEndTime(null);
            }
        } else {
            setEndTime(moment(nEndTime).format("YYYY-MM-DD HH:mm"));
            setNotSaved(false);
        }
    };

    // CALCULATES HOURS WORKED FROM START & END TIME
    const handleWorkedHours = (_start, _end, _currBreakTime, _published) => {
        // Checking if night shift
        if (
            moment(_start).format("YYYY-MM-DD") !=
            moment(_end).format("YYYY-MM-DD")
        ) {
            setNightShift(true);
        } else {
            setNightShift(false);
        }

        // CALC TIME BETWEEN START & END
        const mWorked = moment(_end).diff(_start, "minutes");
        const _hWorked = Math.floor(mWorked / 60);
        const _mWorked = Math.floor(mWorked % 60);

        // STOP SHIFTS FROM OVER 16 HOURS
        if (_hWorked > 16) {
            setCannotSave(true);
            handleModelAlert(
                `The total worked time cannot be over 16 hours`,
                "error"
            );
            return;
        } else {
            setCannotSave(false);
        }

        // Set up default break time
        let breakTime = 0;
        if (_currBreakTime == null) {
            breakTime = 0;
        } else {
            breakTime =
                moment(_currBreakTime, "HH:mm").hours() * 60 +
                moment(_currBreakTime, "HH:mm").minutes();
        }

        // If not publushed always calc break
        if (!_published) {
            breakTime = 0;
        }

        // Asign total hours
        setTotalHours(
            `${_hWorked}:${_mWorked < 10 ? `0${_mWorked}` : _mWorked}`
        );

        let _workedTime = mWorked;

        if (!_published) {

            if (employee.position.includes("Packer")) {
                breakTime = 20;
            } else if (employee.position.includes("Warehouse Assistant")) {
                breakTime = 30;
            } else if (employee.underage & (_hWorked >= 4)) {
                breakTime = 30;
            } else if (!employee.underage) {
                if (_hWorked >= 5 && _hWorked < 6) {
                    breakTime = 10;
                } else if (_hWorked >= 6) {
                    let role = employee.position.toLowerCase();
                    if (
                        role.includes("supervisor") ||
                        role.includes("manager") ||
                        role.includes("leader") ||
                        site.toLowerCase().includes("head")
                    ) {
                        breakTime = 30;
                    } else {
                        breakTime = 20;
                    }
                } else {
                    breakTime = 0;
                }
            }
        }

        _workedTime = _workedTime - breakTime;

        // Asign break and worked hours
        setBreakTime(
            `${Math.floor(breakTime / 60)}:${Math.floor(breakTime % 60) < 10
                ? `0${Math.floor(breakTime % 60)}`
                : Math.floor(breakTime % 60)
            }`
        );
        setHoursWorked(
            `${Math.floor(_workedTime / 60)}:${Math.floor(_workedTime % 60) < 10
                ? `0${Math.floor(_workedTime % 60)}`
                : Math.floor(_workedTime % 60)
            }`
        );
    };

    const handleBreakEdit = (time) => {
        setBreakTime(time);
        setNotSaved(false);

        if (actualStartTime!==null && actualEndTime!==null) {
            handleWorkedHours(actualStartTime, actualEndTime, time, published);
        } else {
            handleWorkedHours(startTime, endTime, time, published);
        }
    };

    function tabChange(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    return (
        <Box sx={style} className="shift-modal">
            {/* TITLE */}
            <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "95%",
                    margin: "0 auto",
                }}
            >
                <h2>
                    <span style={{ color: "#2c53a0" }}>
                        {employee.firstName} {employee.lastName}
                    </span>{" "}
                    - {site}
                </h2>
                <h3>{moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")}</h3>
            </Typography>

            {/* ERROR */}
            {alert ? (
                <Alert
                    severity={alertLevel}
                    sx={{
                        left: "10vw",

                        top: "35vh",
                        zIndex: "10",
                        alignContent: "center",
                    }}
                    style={{ alignSelf: "center" }}
                >
                    <AlertTitle>
                        {alertLevel.toLocaleLowerCase() == "error"
                            ? "Error"
                            : "Success"}
                    </AlertTitle>
                    <strong>{alertText}</strong>
                </Alert>
            ) : null}

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                }}
            >
                {/* MAIN COLUMN */}
                <Typography className="main_column">
                    {/* SHIFT TIMES */}
                    {!otherSite ? (
                        <div className="shift_times_area">
                            <h3>Shift Times</h3>
                            {/* IF NOT PUBLISHED THEN EDIT ELSE DISPLAY */}
                            <div className="shift_time">
                                {!published ? (
                                    // SHIFT TIMES EDIT
                                    <>
                                        <div className="time_input">
                                            <label>Starting Time : </label>
                                            <input
                                                type={"time"}
                                                value={
                                                    startTime == null
                                                        ? moment
                                                            .utc(date)
                                                            .format("HH:mm")
                                                        : moment
                                                            .utc(startTime)
                                                            .format("HH:mm")
                                                }
                                                onChange={(e) => {
                                                    handleStartDateTime(
                                                        e.target.value,
                                                        endTime,
                                                        false
                                                    );
                                                }}
                                                style={{ margin: "0%" }}
                                            />
                                        </div>
                                        <div className="time_input">
                                            <label>Finishing Time : </label>
                                            {constOpen ? (
                                                <input
                                                    type={"datetime-local"}
                                                    value={
                                                        endTime == null
                                                            ? moment
                                                                .utc(date)
                                                                .format(
                                                                    "YYYY-MM-DD HH:mm"
                                                                )
                                                            : moment
                                                                .utc(endTime)
                                                                .format(
                                                                    "YYYY-MM-DD HH:mm"
                                                                )
                                                    }
                                                    onChange={(e) =>
                                                        setEndTime(
                                                            e.target.value
                                                        )
                                                    }
                                                    onBlur={() =>
                                                        handleEndDateTime(
                                                            endTime,
                                                            startTime,
                                                            false
                                                        )
                                                    }
                                                    placeholder={date}
                                                    style={{ margin: "0%" }}
                                                    max={moment(
                                                        date,
                                                        "YYYY-MM-DD"
                                                    )
                                                        .add(1, "days")
                                                        .format(
                                                            "YYYY-MM-DD HH:mm"
                                                        )}
                                                    min={moment(
                                                        date,
                                                        "YYYY-MM-DD"
                                                    ).format(
                                                        "YYYY-MM-DD HH:mm"
                                                    )}
                                                />
                                            ) : (
                                                <input
                                                    type={"time"}
                                                    value={
                                                        endTime == null
                                                            ? moment
                                                                .utc(date)
                                                                .format(
                                                                    "HH:mm"
                                                                )
                                                            : moment
                                                                .utc(endTime)
                                                                .format(
                                                                    "HH:mm"
                                                                )
                                                    }
                                                    onChange={(e) =>
                                                        setEndTime(
                                                            `${date} ${e.target.value}`
                                                        )
                                                    }
                                                    onBlur={() =>
                                                        handleEndDateTime(
                                                            endTime,
                                                            startTime,
                                                            false
                                                        )
                                                    }
                                                    placeholder={date}
                                                    style={{ margin: "0%" }}
                                                    max={moment(
                                                        date,
                                                        "YYYY-MM-DD"
                                                    )
                                                        .add(1, "days")
                                                        .format(
                                                            "YYYY-MM-DD HH:mm"
                                                        )}
                                                    min={moment(
                                                        date,
                                                        "YYYY-MM-DD"
                                                    ).format(
                                                        "YYYY-MM-DD HH:mm"
                                                    )}
                                                />
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {nightShift ? (
                                            <>
                                                <p>
                                                    Starting Time :{" "}
                                                    <span>
                                                        {moment
                                                            .utc(startTime)
                                                            .format(
                                                                "YYYY-MM-DD HH:mm"
                                                            )}
                                                    </span>
                                                </p>
                                                <p>
                                                    Finishing Time :
                                                    <span>
                                                        {" "}
                                                        {moment
                                                            .utc(endTime)
                                                            .format(
                                                                "YYYY-MM-DD HH:mm"
                                                            )}
                                                    </span>
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <p>
                                                    Starting Time :{" "}
                                                    <span>
                                                        {moment
                                                            .utc(startTime)
                                                            .format("HH:mm")}
                                                    </span>
                                                </p>
                                                <p>
                                                    Finishing Time :{" "}
                                                    <span>
                                                        {" "}
                                                        {moment
                                                            .utc(endTime)
                                                            .format("HH:mm")}
                                                    </span>
                                                </p>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    ) : null}

                    {/* ACTUAL SHIFT TIMES */}
                    {!otherSite ? (
                        published ? (
                            <div className="shift_times_area">
                                <h3>Actual Shift Times</h3>
                                <div className="shift_time">
                                    {/* IF NOT CONFIRMED THEN ALLOW ACTUAL TIMES*/}
                                    {!confirmed ? (
                                        <>
                                            <div className="time_input">
                                                <label>Starting Time : </label>

                                                {constOpen ? (
                                                    <input
                                                        type={"datetime-local"}
                                                        value={moment
                                                            .utc(
                                                                actualStartTime,
                                                                "YYYY-MM-DD HH:mm"
                                                            )
                                                            .format(
                                                                "YYYY-MM-DD HH:mm"
                                                            )}
                                                        onChange={(e) =>
                                                            handleStartDateTime(
                                                                e.target.value,
                                                                actualEndTime,
                                                                true
                                                            )
                                                        }
                                                        style={{ margin: "0%" }}
                                                        min={moment(
                                                            date,
                                                            "YYYY-MM-DD"
                                                        ).format("YYYY-MM-DD")}
                                                        max={moment(
                                                            date,
                                                            "YYYY-MM-DD"
                                                        ).format(
                                                            "YYYY-MM-DD HH:mm"
                                                        )}
                                                    />
                                                ) : (
                                                    <input
                                                        type={"time"}
                                                        value={
                                                            actualStartTime ==
                                                                null
                                                                ? moment
                                                                    .utc(date)
                                                                    .format(
                                                                        "HH:mm"
                                                                    )
                                                                : moment
                                                                    .utc(
                                                                        actualStartTime
                                                                    )
                                                                    .format(
                                                                        "HH:mm"
                                                                    )
                                                        }
                                                        onChange={(e) =>
                                                            setActualStartTime(
                                                                `${date} ${e.target.value}`
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            handleStartDateTime(
                                                                actualStartTime,
                                                                actualEndTime,
                                                                true
                                                            )
                                                        }
                                                        placeholder={date}
                                                        style={{ margin: "0%" }}
                                                        max={moment(
                                                            date,
                                                            "YYYY-MM-DD"
                                                        )
                                                            .add(1, "days")
                                                            .format(
                                                                "YYYY-MM-DD HH:mm"
                                                            )}
                                                        min={moment(
                                                            date,
                                                            "YYYY-MM-DD"
                                                        ).format(
                                                            "YYYY-MM-DD HH:mm"
                                                        )}
                                                    />
                                                )}
                                            </div>
                                            <div className="time_input">
                                                <label>Finishing Time : </label>
                                                {constOpen ? (
                                                    <input
                                                        type={"datetime-local"}
                                                        value={moment
                                                            .utc(
                                                                actualEndTime,
                                                                "YYYY-MM-DD HH:mm"
                                                            )
                                                            .format(
                                                                "YYYY-MM-DD HH:mm"
                                                            )}
                                                        onChange={(e) =>
                                                            handleEndDateTime(
                                                                e.target.value,
                                                                actualStartTime,
                                                                true
                                                            )
                                                        }
                                                        style={{ margin: "0%" }}
                                                        max={moment(
                                                            date,
                                                            "YYYY-MM-DD"
                                                        )
                                                            .add(1, "days")
                                                            .format(
                                                                "YYYY-MM-DD HH:mm"
                                                            )}
                                                    />
                                                ) : (
                                                    <input
                                                        type={"time"}
                                                        value={
                                                            endTime == null
                                                                ? moment
                                                                    .utc(date)
                                                                    .format(
                                                                        "HH:mm"
                                                                    )
                                                                : moment
                                                                    .utc(
                                                                        actualEndTime
                                                                    )
                                                                    .format(
                                                                        "HH:mm"
                                                                    )
                                                        }
                                                        onChange={(e) =>
                                                            setActualEndTime(
                                                                `${date} ${e.target.value}`
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            handleEndDateTime(
                                                                actualEndTime,
                                                                actualStartTime,
                                                                true
                                                            )
                                                        }
                                                        placeholder={date}
                                                        style={{ margin: "0%" }}
                                                        max={moment(
                                                            date,
                                                            "YYYY-MM-DD"
                                                        )
                                                            .add(1, "days")
                                                            .format(
                                                                "YYYY-MM-DD HH:mm"
                                                            )}
                                                        min={moment(
                                                            date,
                                                            "YYYY-MM-DD"
                                                        ).format(
                                                            "YYYY-MM-DD HH:mm"
                                                        )}
                                                    />
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {nightShift ? (
                                                <>
                                                    <p>
                                                        Starting Time :{" "}
                                                        <span>
                                                            {moment
                                                                .utc(
                                                                    actualStartTime
                                                                )
                                                                .format(
                                                                    "YYYY-MM-DD HH:mm"
                                                                )}
                                                        </span>
                                                    </p>
                                                    <p>
                                                        Finishing Time :
                                                        <span>
                                                            {" "}
                                                            {moment
                                                                .utc(
                                                                    actualEndTime
                                                                )
                                                                .format(
                                                                    "YYYY-MM-DD HH:mm"
                                                                )}
                                                        </span>
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <p>
                                                        Starting Time :{" "}
                                                        <span>
                                                            {moment
                                                                .utc(
                                                                    actualStartTime
                                                                )
                                                                .format(
                                                                    "HH:mm"
                                                                )}
                                                        </span>
                                                    </p>
                                                    <p>
                                                        Finishing Time :{" "}
                                                        <span>
                                                            {" "}
                                                            {moment
                                                                .utc(
                                                                    actualEndTime
                                                                )
                                                                .format(
                                                                    "HH:mm"
                                                                )}
                                                        </span>
                                                    </p>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        ) : null
                    ) : null}

                    {/* TAB MENU */}

                    {shiftId != null ? (
                        <div className="tabs">
                            <Tabs
                                value={tabPage}
                                onChange={(e, val) => {
                                    setTabPage(val);
                                }}
                                aria-label="basic tabs example"
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                            >
                                <Tab label="NOTES" {...tabChange(0)} />
                                <Tab label="LOGS" {...tabChange(1)} />
                            </Tabs>

                            <TabPanel
                                value={tabPage}
                                index={0}
                                className="tab_panel"
                            >
                                <NotesTab
                                    shiftId={shiftId}
                                    user={user}
                                    _notes={notes}
                                    reload={reload}
                                    error={handleModelAlert}
                                    baseUrl={hrUrl}
                                    config={config}
                                />
                            </TabPanel>

                            <TabPanel
                                className="tab_panel"
                                value={tabPage}
                                index={1}
                            >
                                <LogsTab _logs={logs} />
                            </TabPanel>
                        </div>
                    ) : null}
                </Typography>

                {/* SIDE BAR */}
                <Typography className="side_bar">
                    {/* HOURS WORKED BREAK DOWN */}

                    {startTime !== null && endTime !== null ? (
                        <div className="worked_breakdown">
                            <p>
                                TOTAL HOURS : <span>{totalHours}</span>
                            </p>
                            <p>
                                HOURS WORKED : <span>{hoursWorked}</span>
                            </p>
                            <div className="break">
                                {!editBreak ? (
                                    <>
                                        <p>
                                            BREAK : <span>{breakTime}</span>
                                        </p>
                                        {!confirmed && published ? (
                                            !otherSite ? (
                                                <EditIcon
                                                    onClick={() =>
                                                        setEditBreak(true)
                                                    }
                                                />
                                            ) : null
                                        ) : null}
                                    </>
                                ) : (
                                    <>
                                        <input
                                            type={"time"}
                                            value={moment
                                                .utc(breakTime, "HH:mm")
                                                .format("HH:mm")}
                                            onChange={(e) =>
                                                handleBreakEdit(e.target.value)
                                            }
                                        />
                                        <EditIcon
                                            onClick={() => setEditBreak(false)}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    ) : null}

                    <div className="icon_list">
                        {!nightShift ? (
                            <LightModeIcon
                                color="warning"
                                fontSize="large"
                                className="icon"
                            />
                        ) : (
                            <ModeNightIcon
                                color="error"
                                fontSize="large"
                                className="icon"
                            />
                        )}

                        {noShow ? (
                            <VaccinesIcon
                                color="error"
                                fontSize="large"
                                className="icon"
                            />
                        ) : null}
                    </div>

                    {/* BUTTON COLLECTION */}
                    <div className="buttons">
                        {!otherSite ? (
                            !confirmed ? (
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={handleSave}
                                    className="button"
                                    disabled={cannotSave}
                                >
                                    SAVE
                                </Button>
                            ) : null
                        ) : null}
                        {!otherSite ? (
                            !confirmed ? (
                                shiftId ? (
                                    <Button
                                        variant="contained"
                                        onClick={() => setAttendanceModal(true)}
                                        className="button"
                                        disabled={cannotSave}
                                    >
                                        Attendance
                                    </Button>
                                ) : null
                            ) : null
                        ) : null}

                        {!otherSite ? (
                            shiftId != null && !published ? (
                                <Button
                                    variant="contained"
                                    color="error"
                                    className="button"
                                    onClick={() => handleConfirmationOpen()}
                                >
                                    DELETE
                                </Button>
                            ) : null
                        ) : null}

                        {!otherSite ? (
                            !confirmed ? (
                                <>
                                    {/* Show Publish button or Un Publish and Confirm */}
                                    {!published ? (
                                        <>
                                            {moment().isBefore(date) ||
                                                moment().format("YYYY-MM-DD") ==
                                                date ? (
                                                <Button
                                                    variant="contained"
                                                    color="info"
                                                    onClick={() =>
                                                        handlePublishStatus(
                                                            true
                                                        )
                                                    }
                                                    className="button"
                                                    disabled={cannotSave}
                                                >
                                                    PUBLISH
                                                </Button>
                                            ) : null}
                                        </>
                                    ) : (
                                        <>
                                            {moment().isBefore(date) ||
                                                moment().format("YYYY-MM-DD") ==
                                                date ? (
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() =>
                                                        handlePublishStatus(
                                                            false
                                                        )
                                                    }
                                                    className="button"
                                                >
                                                    UN-PUBLISH
                                                </Button>
                                            ) : null}

                                            {moment().isAfter(date) ||
                                                moment().format("YYYY-MM-DD") ==
                                                date ? (
                                                <Button
                                                    variant="contained"
                                                    color="info"
                                                    className="button"
                                                    onClick={() =>
                                                        handleConfirm(false)
                                                    }
                                                    disabled={
                                                        moment(
                                                            actualEndTime,
                                                            "YYYY-MM-DD HH:mm"
                                                        ).format() >
                                                        moment().format()
                                                    }
                                                >
                                                    CONFIRM FOR PAYROLL
                                                </Button>
                                            ) : null}
                                        </>
                                    )}
                                </>
                            ) : null
                        ) : null}

                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleOpenConfirmation}
                            className="button"
                        >
                            CLOSE
                        </Button>
                    </div>
                </Typography>
            </div>

            <Modal
                open={confirmationOpen}
                aria-labelledby="Delete Confirmation"
                aria-describedby="Delete Confirmation"
            >
                <ConfirmModal
                    confirmDelete={handleDelete}
                    cancelDelete={handleConfirmationClose}
                />
            </Modal>
            <Modal
                open={closeConfirmation}
                aria-labelledby="Close Confirmation"
                aria-describedby="Close Confirmation"
            >
                <CloseWarning
                    handleClose={handleClose}
                    handleCloseConfirmation={handleCloseConfirmation}
                />
            </Modal>
            <Modal
                open={attendanceModal}
                aria-labelledby="Attendance Modal"
                aria-describedby="Attendance Modal"
            >
                <Attendance
                    closeModal={setAttendanceModal}
                    handleClose={handleClose}
                    user={user}
                    shiftId={shiftId}
                    date={date}
                    reload={reloadData}
                    confirmShift={handleConfirm}
                    shiftData={shiftData}
                />
            </Modal>
        </Box>
    );
}

const ConfirmModal = ({ confirmDelete, cancelDelete }) => {
    let style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "30%",
        height: "30%",
        bgcolor: "background.paper",
        border: `solid red`,
        borderRadius: "1rem",
        boxShadow: 24,
        p: 4,
        overflowY: "auto",
    };

    return (
        <Box sx={style}>
            <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ textAlign: "center" }}
            >
                <h3>DELETE CONFIRMATION</h3>
            </Typography>

            <Typography
                style={{
                    textAlign: "center",
                    width: "100%",
                }}
            >
                <p>Are you sure you want to delete this shift?</p>
                <br />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        variant="contained"
                        color="info"
                        onClick={() => cancelDelete()}
                        className="button"
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => confirmDelete()}
                        className="button"
                    >
                        Delete
                    </Button>
                </div>
            </Typography>
        </Box>
    );
};

const CloseWarning = ({ handleClose, handleCloseConfirmation }) => {
    let style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "30%",
        height: "30%",
        bgcolor: "background.paper",
        border: `solid red`,
        borderRadius: "1rem",
        boxShadow: 24,
        p: 4,
        overflowY: "auto",
    };

    return (
        <Box sx={style}>
            <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ textAlign: "center" }}
            >
                <h3>CLOSE CONFIRMATION</h3>
            </Typography>

            <Typography
                style={{
                    textAlign: "center",
                    width: "100%",
                }}
            >
                <p>Are you sure you want to close without saving?</p>
                <br />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Button
                        variant="contained"
                        color="info"
                        onClick={handleCloseConfirmation}
                        className="button"
                    >
                        Go back
                    </Button>

                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                        className="button"
                    >
                        Confirm Close
                    </Button>
                </div>
            </Typography>
        </Box>
    );
};
