import { useState, useEffect, useMemo } from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import DevNav from "../nav/DevNav";
import Drawer from "@mui/material/Drawer";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import VideoChatIcon from "@mui/icons-material/VideoChat";
import CCTVRequests from "./CCTVRequests";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import DriveoffRequests from "./DriveoffRequests";
import Matrix from "./Matrix";
import { Navigate } from "react-router-dom";
import NMOPRequests from "./NMOPRequests";
import axios from "axios";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import PoliceRequest from "./PoliceRequest";
import NavBar from "../../NavBar";

export default function CCTVHome() {
  const url = process.env.REACT_APP_ANPR_URL;
  const [value, setValue] = useState(0);
  const [statusList, setStatusList] = useState([]);
  const [role, setRole] = useState("")

  useMemo(async () => {
    // TODO: ADD THIS TO UTILS
    axios
      .get(`${url}/cctv-system/statuses`)
      .then((res) => {
        setStatusList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setRole(user.user.role)
    if (user.user.role != "Developer" && user.user.role != "HR") {
      return <Navigate to="/" />;
    }
  }, []);

  const handleChange = (index) => {
    setValue(parseInt(index) + 1);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          padding: "0",
        }}
      >
        <Toolbar
          sx={{
            width: "100vw",
            backgroundColor: "#2c53a0",
          }}
        >
          {role == "HR" || role === "Manager" ?
            <NavBar />
            : <DevNav />}
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent">
        <Toolbar />
        <Box sx={{ overflow: "auto", marginTop: "12vh" }}>
          <List>
            {["CCTV", "Drive Off", "NMOP", "Police Alert"].map(
              (text, index) => (
                <ListItem
                  key={text}
                  disablePadding
                  onClick={() => handleChange(index)}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      {index === 0 ? (
                        <VideoChatIcon />
                      ) : index === 1 ? (
                        <CarCrashIcon />
                      ) : index === 2 ? (
                        <LocalGasStationIcon />
                      ) : index === 3 ? (
                        <LocalPoliceIcon />
                      ) : null}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              )
            )}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "10vh" }}>
        <Toolbar />
        {value === 1 ? (
          <CCTVRequests statusList={statusList} />
        ) : value === 2 ? (
          <DriveoffRequests statusList={statusList} />
        ) : value === 3 ? (
          <NMOPRequests statusList={statusList} />
        ) : value === 4 ? (
          <PoliceRequest />
        ) : (
          <Matrix />
        )}
      </Box>
    </Box>
  );
}
