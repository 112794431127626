import "./v4u.scss";
import { useState, useEffect } from "react";
import NavBar from "../../NavBar";
import Footer from "../../Footer";
import axios from "axios";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import NitroLoader from "../../Fuel/loader/NitroLoader";
import Button from "@mui/material/Button";
import Tick from "../../animations/Tick";
import Alert from "@mui/material/Alert";
import DispOrder from "./DispOrder";
import WareNav from "../WareNav";
import { Navigate } from "react-router-dom";

export default function V4uDisposables(props) {
  const [role, setRole] = useState("");
  const [user, setUser] = useState("");
  const [today, setToday] = useState("");
  const [sites, setSites] = useState([]);
  const [site, setSite] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [order, setOrder] = useState([]);
  const [showTick, setShowtick] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [orderAlert, setOrderAlert] = useState(false);
  const url = "https://nitropos-backend.nitrosolutions.co.uk"


  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);
    setRole(user.user.role);

    if (
      user.user.role !== "Developer" &&
      user.user.user !== "guna@ghsl.uk" &&
      user.user.role !== "Warehouse Manager" &&
      user.user.role !== "V4U Manager"
    ) {
      return <Navigate to="/" />;
    } else {
      //checks the day of the week
      let day = new Date().getDay();
      if (day === 0) {
        setToday("Sunday");
      } else if (day === 1) {
        setToday("Monday");
      } else if (day === 2) {
        setToday("Tuesday");
      } else if (day === 3) {
        setToday("Wednesday");
      } else if (day === 4) {
        setToday("Thursday");
      } else if (day === 5) {
        setToday("Friday");
      } else {
        setToday("Saturday");
      }

      //gets all sites
      axios
        .get(
          "https://nitropos-backend.nitrosolutions.co.uk/managerZone/locations"
        )
        .then((res) => {
          setSites(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const handleKey = (e) => {
    if (e.keyCode === 13) {
      handleOrder();
    }
  };

  //selects site and calls for their stock and order generation.
  const handleOrder = () => {
    if (site === "" || site === "--Select a Site--") {
      setMessage("Please select a site");
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
        setMessage("");
      }, 2500);
    } else {
      //checks if an picklist is ready for that site
      axios
        .get(
          `https://nitropos-backend.nitrosolutions.co.uk/edn/v4u/disposables/checkSite/${site}`
        )
        .then((res) => {
          // console.log(res.data);
          if (res.data === true) {
            setMessage("This Store has an order created waiting to be picked");
            setOrderAlert(true);
            setTimeout(() => {
              setOrderAlert(false);
              setMessage("");
            }, 4000);
          } else {
            setShowLoader(true);
            axios
              .get(
                // `http://10.0.0.174:4022/edn/v4u/disposables/${site}`,
                `https://nitropos-backend.nitrosolutions.co.uk/edn/v4u/disposables/${site}`,
                { timeout: 180000 }
              )
              .then((res) => {
                console.log(res.data);
                setShowLoader(false);
                setOrder(res.data);
                setShowOrder(true);
              })
              .catch((err) => {
                console.log(err);
                setShowLoader(false);
                setMessage("Something went wrong, please try again");
                setShowErrorAlert(true);
                setTimeout(() => {
                  setMessage("");
                  setShowErrorAlert(false);
                }, 3000);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //changes the stock selected by the system
  const changeQty = (row, index) => {
    let orderCopy = order;
    orderCopy[index] = row;
    setOrder(orderCopy);
  };

  //creates the order as an EDN
  const createOrder = () => {
    document.getElementById("create_but").style.display = "none";
    let data = {
      products: order,
      user: user,
      siteId: site,
    };
    axios
      .post(
        `${url}/edn/v4u/disposables/order`,
        data
      )
      .then((res) => {
        setShowtick(true);
        setTimeout(() => {
          setShowtick(false);
          document.getElementById("create_but").style.display = "flex";
          setShowOrder(false);
        }, 3000);
      })
      .catch((err) => {
        setMessage("Whoops something went wrong, please try again");
        console.log(`Could not send the order: ${err}`)
        setShowErrorAlert(true);
        setTimeout(() => {
          setShowErrorAlert(false);
          document.getElementById("create_but").style.display = "flex";
        }, 3000);
      });
  };

  return (
    <main className="v4u__ordering">
      <main className="v4u__main">
        {user === "guna@ghsl.uk" || role === "Developer" ? (
          <WareNav />
        ) : (
          <WareNav />
        )}
        <section className="ordering__main">
          <h1>Disposables ordering system</h1>
          <h4>Today is: {today}</h4>
          {today === "Monday" || today === "Thursday" ? (
            <h4 style={{ color: "#D90D1E" }}>
              Select a store and check the automatic order
            </h4>
          ) : (
            <h4>Today is not an ordering day</h4>
          )}

          <div className="ordering__row">
            <select
              onChange={(e) => setSite(e.target.value)}
              onKeyDown={handleKey}
            >
              <option>--Select a Site--</option>
              {sites.map(function (d, i) {
                return <option value={d.Id}>{d.Name}</option>;
              })}
            </select>
            <CenterFocusStrongIcon
              color="primary"
              fontSize="large"
              onClick={() => handleOrder()}
            />
          </div>
          {showErrorAlert ? (
            <Alert severity="warning" sx={{ marginTop: "2%" }}>
              {message}
            </Alert>
          ) : (
            <></>
          )}
          {orderAlert ? (
            <Alert severity="warning" sx={{ marginTop: "2%" }}>
              {message}
            </Alert>
          ) : (
            <></>
          )}
          {showLoader ? (
            <NitroLoader />
          ) : showOrder ? (
            <div className="order__table">
              <table>
                <thead>
                  <th>Product</th>
                  <th>Store Stock</th>
                  <th>Order Qty</th>
                  <th>Warehouse Stock</th>
                  <th>Last 7 days Sales</th>
                </thead>
                <tbody>
                  {order.length > 0 ? (
                    order.map(function (d, i, array) {
                      var newBrand = "";
                      if (array[i + 1]) {
                        var first = array[i + 1].type;
                        var now = array[i].type;
                        if (first[0] !== now[0]) {
                          newBrand = "VAPED4U";
                        }
                      }
                      return (
                        <>
                          <DispOrder data={d} index={i} changeQty={changeQty} />
                          {newBrand != "" ? (
                            <tr style={{backgroundColor: "#2c53a0", color: "#fff"}}>
                              <td
                                colspan={5}
                                style={{
                                  fontWeight: "800",
                                  letterSpacing: ".5rem",
                                }}
                              >
                                VAPED4U
                              </td>
                            </tr>
                          ) : null}
                        </>
                      );
                    })
                  ) : (
                    <tr>
                      <td colspan={5}>No Order To Show</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Button
                color="success"
                variant="outlined"
                id="create_but"
                onClick={() => createOrder()}
              >
                Create Order
              </Button>
            </div>
          ) : (
            <></>
          )}
        </section>
        <Footer />
      </main>
    </main>
  );
}
