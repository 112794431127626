import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ManagerNav from "../ManagerNav";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import Banking from "./Banking";
import HistoryIcon from "@mui/icons-material/History";
import BankingHistory from "./BankingHistory";
import ShiftRecon from "./ShiftRecon";
import AdjustIcon from "@mui/icons-material/Adjust";
import Discrepancies from "./Discrepancies";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import EodReports from "./EodReports";
import BackHandIcon from "@mui/icons-material/BackHand";
import ManualDiscrep from "./discrepancies/ManualDiscrep";
import BreakfastDiningIcon from "@mui/icons-material/BreakfastDining";
import SubwayHome from "./subway/SubwayHome";
import ErrorBoundary from "../../error/ErrorBoundary";
import ViewDiscrepsHome from "./discrepsview/ViewDiscrepsHome";
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';

const drawerWidth = "12vw";

export default function EodHome(props) {
  const [role, setRole] = useState("");
  const [user, setUser] = useState("");
  const [value, setValue] = useState(1);

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);
    setUser(user.user.user);
    setRole(user.user.role);
  }, []);

  const handleChange = (index) => {
    setValue(index + 1);
  };

  return (
    <main className="dashboard">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, padding: "0" }}
        >
          <Toolbar sx={{ width: "100vw", backgroundColor: "#2c53a0" }}>
            <ManagerNav />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 1,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
            ["@media(max-width: 600px)"]: {
              width: "10vw",
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto", marginTop: "12vh" }}>
            <List>
              {[
                "EOD",
                "Banking",
                "History",
                "Discrepancies",
                "Discrepancy Charges",
                "Manual Discrepancy",
                "EOD Reports",
                "Subway EOD",
              ].map((text, index) => (
                <ListItem
                  key={text}
                  disablePadding
                  onClick={() => handleChange(index)}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      {index === 0 ? (
                        <PointOfSaleIcon  />
                      ) : index === 1 ? (
                        <AccountBalanceIcon />
                      ) : index === 2 ? (
                        <HistoryIcon />
                      ) : index === 3 ? (
                        <AdjustIcon  />
                      ) 
                      : index === 4 ? (
                        <CreditCardOffIcon />
                      ) 
                      : index === 5 ? (
                        <BackHandIcon  />
                      ) : index === 6 ? (
                        <ManageSearchIcon  />
                      ) : index === 7 ? (
                        <BreakfastDiningIcon />
                      ) : null}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "10vh" }}>
          <Toolbar />
          {value === 1 ? (
            <ErrorBoundary>
              <ShiftRecon />
            </ErrorBoundary>
          ) : value === 2 &&
            (role === "Manager" ||
              user === "nadeem@racetrackpitstop.co.uk" ||
              role === "Developer") ? (
            <ErrorBoundary>
              <Banking />
            </ErrorBoundary>
          ) : value === 3 &&
            (role === "Manager" ||
              user === "nadeem@racetrackpitstop.co.uk" ||
              role === "Developer") ? (
            <ErrorBoundary>
              <BankingHistory />
            </ErrorBoundary>
          ) : value === 4 ? (
            <ErrorBoundary>
              <Discrepancies />
            </ErrorBoundary>
          ) 
          : value === 5 ? (
            <ErrorBoundary>
              <ViewDiscrepsHome />
            </ErrorBoundary>
          ) 
          : value === 6 ? (
            <ErrorBoundary>
              <ManualDiscrep />
            </ErrorBoundary>
          ) : value === 7 ? (
            <ErrorBoundary>
              <EodReports />
            </ErrorBoundary>
          ) : value === 8 ? (
            <SubwayHome />
          ) : (
            <h1>You don't have permissions for this</h1>
          )}
        </Box>
      </Box>
    </main>
  );
}
