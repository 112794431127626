import Footer from "../../Footer";
import WareNav from "../WareNav";
import { useEffect, useState } from "react";
import axios from "axios";
import Alert from "@mui/material/Alert";
import './invoiceCheck.scss';
import { getAllStores } from "../../utils/Api";

export default function RunDispos() {

  const url = "https://nitropos-backend.nitrosolutions.co.uk";
  //const url = "http://10.0.0.174:4022";

  const [loader, setLoader] = useState(false);
  //alerts
  const [showAlert, setShowAlert] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [sites, setSites] = useState([])
  const [siteName, setSiteName] = useState("")

  useEffect(() => {
    getAllStores()
      .then((data) => {
        setSites(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])

  const runScript = () => {
    setLoader(true);
    setTimeout(() => {
      if (loader) {
        setLoader(false);
      }
    }, 300000);
  };

  const runScriptNew = (type, site) => {
    if (siteName == "" && !site) {
      setMessage("Please Select A Site!");
      setSeverity("error");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    } else {
      let tempSite = siteName;
      if (site) {
        tempSite = site
      }
      setLoader(true);
      axios.get(`${url}/edn/scripts/${tempSite}/${type}`).then((res) => {
        console.log(res.data);
      }).catch((err) => {
        setMessage("There was an error starting the script");
        setSeverity("error");
        setLoader(false);
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      });
      setTimeout(() => {
        setLoader(false);
        setSiteName("")
      }, 300000);
    }
  };

  return (
    <main>
      <WareNav />
      <section className="dispos_main">
        <h1>Manually Run Scripts</h1>
        {!loader ? (
          <table>
            <thead>
              <th>Site</th>
              <th>Script</th>
            </thead>
            <tbody>
              <tr>
                <td>Warehouse</td>
                <td><button onClick={() => runScriptNew("disposable","Warehouse") }>Disposables Stock</button></td>
              </tr>
              <tr>
                <td>
                  <select onChange={(e) => setSiteName(e.target.value)}>
                    <option>--- Select Site ---</option>
                    {sites.map(function (d, i) {
                      return (
                        <option key={i}>{d.siteName}</option>
                      )
                    })}
                  </select>
                </td>
                <td><button onClick={() => runScriptNew("disposable")}>Site Disposables</button></td>
              </tr>
              <tr>
                <td>
                  <select onChange={(e) => setSiteName(e.target.value)}>
                    <option>--- Select Site ---</option>
                    {sites.map(function (d, i) {
                      return (
                        <option key={i}>{d.siteName}</option>
                      )
                    })}
                  </select>
                </td>
                <td><button onClick={() => runScriptNew("Vape")}>Vape Ordering</button></td>
              </tr>
              <tr>
                <td>
                  <select onChange={(e) => setSiteName(e.target.value)}>
                    <option>--- Select Site ---</option>
                    {sites.map(function (d, i) {
                      return (
                        <option key={i}>{d.siteName}</option>
                      )
                    })}
                  </select>
                </td>
                <td><button onClick={() => runScriptNew("Keg")}>Keg Ordering</button></td>
              </tr>
            </tbody>
          </table>
        ) : (
          <section className="loader_container">
            <strong>Please wait, this takes about 5-10 minutes to run</strong>
            <div className="loader_circles">
              <div className="circle">
                <div className="dot"></div>
                <div className="outline"></div>
              </div>
              <div className="circle">
                <div className="dot"></div>
                <div className="outline"></div>
              </div>
              <div className="circle">
                <div className="dot"></div>
                <div className="outline"></div>
              </div>
              <div className="circle">
                <div className="dot"></div>
                <div className="outline"></div>
              </div>
            </div>
          </section>
        )}
        {showAlert ? (
          <Alert severity={severity} sx={{ marginTop: "0.5%" }}>
            {message}
          </Alert>
        ) : null}
      </section>
      <Footer />
    </main>
  );
}
