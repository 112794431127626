import Footer from "../Footer";
import "./styles/invoices.scss";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserContext from "../context/userContext";
import TrainingLogo from "./images/RaceTrack-Ordering.png";
import BookerLogo from "./images/booker-logo.png";
import LondisLogo from "./images/Londis.webp";
import StoreNav from "./StoreNav";
import V4U from "./images/v4uLogo.webp";

//modal imports
import Modal from "@mui/material/Modal";
import IndvHistory from "./IndvHistory";


export default function History() {
  const [data, setData] = useState([]);
  const [invoice, setInvoice] = useState("");
  const { userData } = useContext(UserContext);
  const [site, setSite] = useState("");
  const navigate = useNavigate();
  const [supplier, setSupplier] = useState();
  const [products, setProducts] = useState([])

  //modal open
  const [open, setOpen] = useState(false);
  const handleOpen = (invoice, productsOrdered) => {
    setOpen(true);
    setInvoice(invoice)
    setProducts(productsOrdered)
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(async () => {
    let s = localStorage.getItem("site");
    setSite(JSON.parse(s));
    let sup = localStorage.getItem("supplier");
    setSupplier(JSON.parse(sup));

    await axios
      .get(
        `https://nitropos-backend.nitrosolutions.co.uk/edn/history?site=${JSON.parse(
          s
        )}&supplier=${JSON.parse(sup)}`
      )
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        // var updated = res.data[res.data.length - 1]
        // setUpdate(updated.invoiceDate)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function getInvoice(invoice) {
    localStorage.setItem("invoice", JSON.stringify(invoice));
    // navigate(`/storezone/Orders`);
  }

  return (
    <section className="invoices">
      <StoreNav site={site} />
      <section className="checker_main">
        <h1>Check last 5 invoices history</h1>
        {data.map((i) => {
          return (
            <IndvHistory invoice={i} />
          )
        })}
        </section>
      <Footer />
    </section>
  );
}
