import Footer from "../Footer";
import "./managerZone.scss";
import { useState, useEffect, useContext } from "react";
import ManagerNav from "./ManagerNav";
import userContext from "../context/userContext";
import axios from "axios";
import AlertPanel from "./alertPanelTabs/AlertPanel";
import { getSites } from "../utils/Api";
import Loader from "../animations/Loader";
import { Alert } from "@mui/material";
import Dialog from "@mui/material/Dialog";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
};

export default function ManagerHome(props) {
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    // const url = "http://10.0.0.13:4022";
    const [sites, setSites] = useState([]);
    const { userData } = useContext(userContext);
    const [user, setUser] = useState("");
    const [hour, setHour] = useState(0);
    const [reload, setReload] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const [email, setEmail] = useState("");
    const [showLoader, setShowLoader] = useState(true);
    // alert
    const [showAlert, setShowAlert] = useState(false);
    const [severity, setSeverity] = useState("");
    const [message, setMessage] = useState("");

    const handleClose = () => {
        setShowAlert(false);
    };

    useEffect(() => {
        var username = "";
        if (userData.user.user) {
            var split = userData.user.user.split("@");
            username = split[0].replace(".", " ");
            setUser(username);
        }
        let today = new Date();
        let hours = today.getHours();
        setHour(hours);

        let userD = localStorage.getItem("userDetails");
        let user = JSON.parse(userD);
        setEmail(user.user.user);
        let userSites = user.sites;
        getRtSites(userSites);
    }, [reload]);

    const getRtSites = async (siteList) => {
        let allRtSites = [];
        let eposSites = [];

        await getSites()
            .then((allSites) => {
                allRtSites = allSites;
            })
            .catch((err) => {
                console.log(`Could not get sites: ${err}`);
            });

        for (let i = 0; i < allRtSites.length; i++) {
            for (let s = 0; s < siteList.length; s++) {
                if (siteList[s] === allRtSites[i].name) {
                    eposSites.push(allRtSites[i].epos);
                }
            }
        }
        setSites(eposSites);

        try {
            getCCTVAlerts(eposSites);
        } catch (err) {
            console.log(`Could not get the alerts: ${err}`);
        }
    };

    const rel = () => {
        setReload(!reload);
    };

    const getCCTVAlerts = async (epos) => {
        await axios
            .post(`${url}/alerts/active`, { sites: epos })
            .then((res) => {
                console.log(res.data);
                setAlerts(res.data);
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(`Could not get the alerts: ${err}`);
            });
    };

    const readAlert = (logId, comment) => {
        const data = {
            alertId: logId,
            user: email,
        };

        const commentData = {
            alertId: logId,
            addedBy: email,
            comment: comment,
        };

        if (comment === "") {
            setMessage(
                "Please enter the action you have taken before marking as read"
            );
            setSeverity("warning");
            setShowAlert(true);
            setTimeout(() => {
                setShowAlert(false);
                setSeverity("");
                setMessage("");
            }, 3000);
        } else {
            axios
                .post(`${url}/alerts/viewed`, data)
                .then((res) => {
                    axios
                        .post(`${url}/alerts/submitComment`, commentData)
                        .then((res) => {
                            setMessage("Marked as read");
                            setSeverity("success");
                            setShowAlert(true);
                            setTimeout(() => {
                                setShowAlert(false);
                                setSeverity("");
                                setMessage("");
                                rel();
                            }, 3000);
                        })
                        .catch((err) => {});
                })
                .catch((err) => {
                    console.log(`Could not mark as read: ${err}`);
                    setMessage(
                        "Could not mark the alert as read, please try again"
                    );
                    setSeverity("error");
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setSeverity("");
                        setMessage("");
                    }, 3000);
                });
        }
    };

    return (
        <main>
            <ManagerNav />
            <section className="manager__dash">
                <div className="welcome__bar">
                    {hour < 12 ? (
                        <b>Good Morning</b>
                    ) : hour > 12 && hour < 17 ? (
                        <b>Good Afternoon</b>
                    ) : hour > 17 && hour < 21 ? (
                        <b>Good Evening</b>
                    ) : (
                        <b>Hello</b>
                    )}
                    <b>{user}</b>
                </div>
                <h1>welcome to Your Alert panel</h1>
                {!showLoader ? (
                    <AlertPanel alerts={alerts} readAlert={readAlert} />
                ) : (
                    <Loader />
                )}
            </section>
            <Footer />
            <Dialog open={showAlert}>
                <Alert severity={severity}>{message}</Alert>
            </Dialog>
        </main>
    );
}
