import Footer from "../Footer";
import "./styles/invoices.scss";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import UserContext from "../context/userContext";
import BookerLogo from "./images/booker-logo.png";
import LondisLogo from './images/Londis.webp'
import StoreNav from "./StoreNav";

export default function Claims() {

    const [data, setData] = useState([]);
    const [site, setSite] = useState("");
    const navigate = useNavigate();


    useEffect(async () => {
        let s = localStorage.getItem("site");
        setSite(JSON.parse(s));
        let sup = localStorage.getItem("supplier");

        await axios
            .get(
                `https://nitropos-backend.nitrosolutions.co.uk/claims/active/${JSON.parse(s)}/${JSON.parse(sup)}`
            )
            .then(async(res) => {
                var claimData = []
                for (let r = 0; r < res.data.length; r++) {
                    var claim = res.data[r];
                    await axios.get(`https://nitropos-backend.nitrosolutions.co.uk/edn/invoice?invoiceNumber=${claim.invoiceNumber}`).then((resp) => {
                        claim.invoiceDate = resp.data.invoiceDate
                    }).catch((err) => {
                        console.log(err)
                    })
                    console.log(claim)
                    claimData.push(claim)
                }

                setData(claimData);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    function getInvoice(invoice) {
        localStorage.setItem("claim", JSON.stringify(invoice));
        navigate(`/storezone/Claim`);
    }

    return (
        <>
            <section className="invoices">
                <StoreNav site={site} />
                <div className="invoiceTable">
                    <h1>Check Claims</h1>
                    <table id="invoices">
                        <tbody>
                            <tr>
                                <th>Invoice Number</th>
                                <th>Supplier</th>
                                <th>Invoice Date</th>
                                <th>Total Items</th>
                            </tr>
                            {data ? (
                                data.map(function (d, i) {
                                    return (
                                        <tr
                                            onClick={() => getInvoice(d.invoiceNumber)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <td style={{ textAlign: "center" }}>{d.invoiceNumber}</td>
                                            <td style={{ textAlign: "center" }}>
                                                {d.supplier === "Booker" ? (
                                                    <>
                                                        <img src={BookerLogo} width="150px" />
                                                        <br />
                                                    </>
                                                ) : (
                                                    <>
                                                        <img src={LondisLogo} width="175px" />
                                                        <br />
                                                        {d.supplier}
                                                    </>
                                                )}
                                            </td>
                                            <td style={{ textAlign: "center" }}>{d.invoiceDate}</td>
                                            <td style={{ textAlign: "center" }}>
                                                {d.products.length}
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </table>
                </div>
                <Footer />
            </section>
        </>
    )
}