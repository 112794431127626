import RT from "../../images/rt-logo.png";
import "./styles/Brands.css";
import Footer from "../Footer";
import { useState, useContext, useEffect } from "react";
import Wrapper from "./RT_Labels/Wrapper";
import RtSearch from "./RT_Labels/RtSearch";
import UserContext from "../context/userContext";
import { useNavigate } from "react-router-dom";
import StoreNav from "./StoreNav";

export default function RaceTrack() {
  const [showLabel, setShowLabel] = useState(false);
  const [showPick, setShowPick] = useState(false);
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const [site, setSite] = useState("")

  useEffect(() => {
    const url2 = new URL(window.location.href);
    var s = url2.searchParams.get("site");
    setSite(s)
  }, [])

  function handleLabel() {
    if (showLabel === false) {
      setShowLabel(true);
    } else setShowLabel(false);
  }

  function handlePick() {
    if (showPick === false) {
      setShowPick(true);
    } else setShowPick(false);
  }

  return (
    <>
      <div className="brand">
        <StoreNav site={site} />
        <div className="brandsContent" style={{margin:"5%"}}>
          <img src={RT} alt="RaceTrack Logo" height="100px" width="100px" />
          <Wrapper>
            <RtSearch site={site}/>
          </Wrapper>          
        </div>
        <Footer />
      </div>
    </>
  );
}
