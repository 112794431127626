//React
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
//Styling
import "./purchase_orders.scss";
//Components
import NavBar from "../NavBar";
import StorePurchaseOrder from "../StoreZone/productManagement/purchase_order/StorePurchaseOrder";
//Mui
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import { Alert } from "@mui/material";
import PendingIcon from "@mui/icons-material/Pending";
import SaveIcon from "@mui/icons-material/Save";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
//Utils
import { getSites } from "../utils/Api";
import { getSuppliers } from "../utils/Api";

export default function PurchaseOrderAdmin() {
    //Navigate
    const navigate = useNavigate();
    const [pageToggle, setPageToggle] = useState(true);
    //Url
    const url = process.env.REACT_APP_DEPPLOYED;
    //Purchase Orders
    const [pendingOrders, setPendingOrders] = useState([]);
    const [pendingOrdersFixed, setPendingOrdersFixed] = useState([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const limit = 10;
    //Filters
    const [sites, setSites] = useState([]);
    const [selectedSite, setSelectedSite] = useState(0);
    const [statuses, setStatuses] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(0);
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(0);
    //Alerts
    const [alert, setAlert] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<any>("");
    const [disable, setDisable] = useState<boolean>(false);

    useEffect(() => {
        //Bring in sites
        getSites()
            .then((res: any) => {
                setSites(res);
            })
            .catch((err) => {
                handleAlert("Unable to get sites", "error");
            });
        //Bring in suppliers
        getSuppliers()
            .then((res: any) => {
                setSuppliers(res);
            })
            .catch((err) => {
                handleAlert("Unable to get Suppliers", "error");
            });
        //Bring in statuses
        axios
            .get(`${url}/purchaseOrder/Statuses`)
            .then((res) => {
                setStatuses(res.data);
                let removeSaveFiltered = res.data.filter(
                    (po: any, i: number) => {
                        return po.statusId != 1;
                    }
                );
                setStatuses(removeSaveFiltered);
            })
            .catch((err) => {
                console.log(err);
                handleAlert("Unable to get statuses", "error");
            });
        //Bring in all POs
        axios
            .post(`${url}/purchaseOrder/GetAll`, {
                limit: limit,
                page: currentPage,
            })
            .then((res) => {
                let removeSaveFiltered = res.data.filter(
                    (po: any, i: number) => {
                        return po.statusId != 1;
                    }
                );
                setPendingOrders(removeSaveFiltered);
                setPendingOrdersFixed(removeSaveFiltered);
            })
            .catch((err) => {
                console.log(err);
                handleAlert("Unable to get purchase orders", "error");
            });
    }, [currentPage]);

    //Alerts
    const handleAlert = (
        message: string,
        severity: string,
        duration: number = 4000
    ): void => {
        setDisable(true);
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setSeverity("");
            setDisable(false);
        }, duration);
    };

    //Filter pending orders
    const filterOrders = (value: number, field: string) => {
        let filterData = {
            siteId: field === "site" ? value : selectedSite,
            supplierId: field === "supplier" ? value : selectedSupplier,
            statusId: field === "status" ? value : selectedStatus,
        };

        //Remove any key/value pairs from the filtered data where the value is 0 (default)
        const removeZeros = (item: any) =>
            Object.keys(item)
                .filter((key) => item[key] !== 0)
                .reduce((newObj: any, key) => {
                    newObj[key] = item[key];
                    return newObj;
                }, {});
        const result = removeZeros(filterData);

        //Create a new filter array of the key value pairs that have to be filtered
        let filteredArray: any = [];
        for (const [key, value] of Object.entries(result)) {
            filteredArray.push({ key: key, value: value });
        }

        //Loop through filter array and set new array
        let finalFiltered = pendingOrdersFixed;
        for (let index = 0; index < filteredArray.length; index++) {
            finalFiltered = finalFiltered.filter(
                (order: any) =>
                    order[filteredArray[index].key] ==
                    filteredArray[index].value
            );
        }

        //Set Current filter selects
        if (field === "site") {
            setSelectedSite(value);
        } else if (field === "supplier") {
            setSelectedSupplier(value);
        } else if (field === "status") {
            setSelectedStatus(value);
        }
        //Set new filtered pending orders array
        setPendingOrders(finalFiltered);
    };

    return (
        <main className="purchase_orders_admin">
            <Box>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        padding: "0",
                    }}
                >
                    <Toolbar
                        sx={{ width: "100vw", backgroundColor: "#2c53a0" }}
                    >
                        <NavBar />
                    </Toolbar>
                </AppBar>
            </Box>

            <section className="po_main">
                <Button
                    className="new_order_btn"
                    variant="contained"
                    onClick={() => setPageToggle(!pageToggle)}
                >
                    {" "}
                    {pageToggle ? (
                        <AddIcon fontSize="small" />
                    ) : (
                        <ArrowBackIcon fontSize="small" />
                    )}
                </Button>
                {pageToggle ? (
                    <>
                        <h1>PO Admin</h1>
                        <section className="purchase_order_filters">
                            <select
                                onChange={(e) => {
                                    filterOrders(
                                        parseInt(e.target.value),
                                        "site"
                                    );
                                }}
                            >
                                <option value={0}>Filter by site</option>
                                {sites &&
                                    sites.map((site: any, i: number) => {
                                        return (
                                            <option key={i} value={site.epos}>
                                                {site.name}
                                            </option>
                                        );
                                    })}
                            </select>
                            <select
                                onChange={(e) => {
                                    filterOrders(
                                        parseInt(e.target.value),
                                        "supplier"
                                    );
                                }}
                            >
                                <option value={0}>Filter by Supplier</option>
                                {suppliers &&
                                    suppliers.map(
                                        (supplier: any, i: number) => {
                                            return (
                                                <option
                                                    key={i}
                                                    value={supplier.eposId}
                                                >
                                                    {supplier.name}
                                                </option>
                                            );
                                        }
                                    )}
                            </select>
                            <select
                                onChange={(e) => {
                                    filterOrders(
                                        parseInt(e.target.value),
                                        "status"
                                    );
                                }}
                            >
                                {" "}
                                <option value={0}>Filter by Status</option>
                                {statuses &&
                                    statuses.map((status: any, i: number) => {
                                        return (
                                            <option
                                                key={i}
                                                value={status.statusId}
                                            >
                                                {status.name}
                                            </option>
                                        );
                                    })}
                            </select>
                        </section>
                        {pendingOrders.length > 0 ? (
                            <table className="po_status_table">
                                <thead>
                                    <tr>
                                        <th>PO ID</th>
                                        <th>Site</th>
                                        <th>Submitted by</th>
                                        <th>Supplier</th>
                                        <th>Submitted on</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pendingOrders.map((po: any, i: number) => {
                                        return (
                                            <tr
                                                style={
                                                    po.statusName === "Approved"
                                                        ? {
                                                              backgroundColor:
                                                                  "#a3ffbc",
                                                          }
                                                        : po.statusName ===
                                                              "Cancelled" ||
                                                          po.statusName ===
                                                              "Denied"
                                                        ? {
                                                              backgroundColor:
                                                                  "#ff938a",
                                                          }
                                                        : po.statusName ===
                                                          "Saved"
                                                        ? {
                                                              backgroundColor:
                                                                  "#fcde7c",
                                                          }
                                                        : {
                                                              backgroundColor:
                                                                  "#FFF",
                                                          }
                                                }
                                                key={i}
                                                onClick={() =>
                                                    navigate(
                                                        `/purchase-order-admin/${po.poId}`
                                                    )
                                                }
                                            >
                                                <td>{po.poId}</td>
                                                <td>{po.siteName}</td>
                                                <td>{po.staff}</td>
                                                <td>{po.supplierName}</td>
                                                <td>
                                                    {moment(
                                                        po.submissionDate
                                                    ).format("DD-MM-YY HH:mm")}
                                                </td>
                                                <td className="status_cell">
                                                    <p>{po.statusName}</p>
                                                    {po.statusName ===
                                                    "Pending" ? (
                                                        <PendingIcon />
                                                    ) : po.statusName ===
                                                      "Saved" ? (
                                                        <SaveIcon />
                                                    ) : po.statusName ===
                                                          "Cancelled" ||
                                                      po.statusName ===
                                                          "Denied" ? (
                                                        <CancelIcon />
                                                    ) : po.statusName ===
                                                      "Approved" ? (
                                                        <CheckBoxIcon />
                                                    ) : null}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        ) : (
                            <h2>No Pending Orders</h2>
                        )}
                    </>
                ) : (
                    <StorePurchaseOrder adminSide={true} />
                )}
                <section className="pagination_btns">
                    {" "}
                    <Button
                        onClick={() => {
                            setCurrentPage(currentPage + 1);
                            window.scrollTo(0, 0);
                        }}
                        disabled={limit < pendingOrders.length}
                        variant="contained"
                    >
                        <ArrowBackIcon />
                    </Button>
                    <h2>{currentPage}</h2>
                    <Button
                        onClick={() => {
                            setCurrentPage(currentPage - 1);
                            window.scrollTo(0, 0);
                        }}
                        disabled={currentPage === 1}
                        variant="contained"
                    >
                        <ArrowForwardIcon />
                    </Button>
                </section>
            </section>
            {/* DIALOGS */}
            <Dialog open={alert} className="po_alert_dialog">
                <Alert className="alert" severity={severity}>
                    {message}
                </Alert>
            </Dialog>
        </main>
    );
}
