import React, { useState, useEffect, useContext } from "react";
import "../styles/fuel.scss";
import NavBar from "../NavBar";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ErrorNotice from "../ErrorNotice";
import Footer from "../Footer";
import FuelOverview from "./FuelOverview";
import SitePrices from "./SitePrices";
import FuelLevel from "./FuelLevel";
import FuelSales from "./FuelSales";
import UserContext from "../context/userContext";
import { useNavigate } from "react-router-dom";
import LoginTest from "../LoginTest";
import Keyfuel from "../reports/Keyfuel";
import DriveOffReport from "../reports/DriveOffReport";
import Suspended from "./Suspended";

export default function FuelHome(props) {
    const [value, setValue] = useState(0);
    const [error, setError] = useState();
    const [showMenuIcon, setShowMenuIcon] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showSideMenu, setShowSideMenu] = useState(true);
    const [role, setRole] = useState("");
    const { userData, setUserData } = useContext(UserContext);
    const navigate = useNavigate();

    const [user, setUser] = useState("");

    useEffect(() => {
        if (window.innerWidth <= 1200) {
            setShowSideMenu(false);
            setShowMenuIcon(true);
            // alert("small")
        } else {
            setShowSideMenu(true);
        }

        let user = sessionStorage.getItem("user");
        setUser(user);

        let role = localStorage.getItem("role");
        setRole(role);
    }, [window.innerWidth]);

    const handleMenu = () => {
        if (showMobileMenu === false) {
            setShowMobileMenu(true);
        } else {
            setShowMobileMenu(false);
        }
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            {userData.user &&
            (userData.user.role === "Manager" ||
                userData.user.role === "Admin" ||
                userData.user.role === "Developer" ||
                userData.user.role === "HR") ? (
                <section className="dashboard">
                    <NavBar />
                    <Box sx={{ width: "100%", marginLeft: "1%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                            >
                                <Tab label="Fuel Reports" {...a11yProps(0)} />
                                <Tab label="Fuel Stats" {...a11yProps(1)} />

                                <Tab
                                    label="Fuel Card Report"
                                    {...a11yProps(2)}
                                />
                                <Tab label="Drive Off" {...a11yProps(3)} />
                                <Tab label="Suspended" {...a11yProps(4)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <FuelSales />
                            {/* <FuelSales handleServerError={props.handleServerError}/> */}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <FuelOverview />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Keyfuel />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <DriveOffReport />
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <Suspended />
                        </TabPanel>
                    </Box>
                    <Footer />
                </section>
            ) : (
                <LoginTest />
            )}
        </>
    );
}
