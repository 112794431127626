import { useEffect, useState } from "react";
import axios from "axios";
import "./eod.scss";
import Button from "@mui/material/Button";
import NitroLoader from "../../Fuel/loader/NitroLoader";
import Alert from "@mui/material/Alert";
import imageCompression from "browser-image-compression";
import { dataUriToBuffer } from "data-uri-to-buffer";

export default function PayoutImages() {
  const [images, setImages] = useState([]);
  const [image, setImage] = useState("");
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [barcode, setBarcode] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showBarcodeInput, setShowBarcodeInput] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const url = "https://nitropos-backend.nitrosolutions.co.uk"
  //const url = "http://10.0.0.174:4022";

  const changeType = (t) => {
    if (t == "NEWSPAPER COUPON") {
      setShowBarcodeInput(true);
    } else {
      setShowBarcodeInput(false);
    }
    setType(t);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      addImage(barcode);
    }
  };

  const addImage = async (img) => {
    if (type == "") {
      setAlertMessage("You must select a payout type before adding an image");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setAlertMessage("");
      }, 2500);
    } else {
      // //compression of the image for upload
      // const options = {
      //   maxSizeMB: 1,
      //   maxWidthOrHeight: 400,
      // };
      // const compressedFile = await imageCompression(img, options);
      let extension = img.name.split(".").pop();
      const fileReader = new FileReader();
      fileReader.readAsDataURL(img);
      fileReader.onload = () => {
        setImages((oldArray) => [
          ...oldArray,
          { type: type, image: fileReader.result, extension: extension },
        ]);
      };
      setType("");
      setImage("");
      setBarcode("");
    }
  };

  const submit = () => {
    document.getElementById("img_submit").style.display = "none";
    setShowLoader(true);

    const urlParams = new URLSearchParams(window.location.search);
    let imgArr = [];
    for (let i = 0; i < images.length; i++) {
      imgArr.push({ type: images[i].type, extension: images[i].extension });
    }
    var data = {
      eodId: parseInt(urlParams.get("id")),
      images: imgArr,
    };
    axios
      .post(`${url}/eos/images`, data)
      .then((res) => {
        console.log(res.data);
        const response = res.data;

        for (let i = 0; i < response.length; i++) {
          let buffer = dataUriToBuffer(images[i].image);

          axios
            .put(response[i].link, buffer.buffer)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(`Could not upload the images to S3: ${err}`);
            });
        }
        setShowLoader(false);
        setMessage("Upload Complete. Please Return To The EOD Reconcile Page");
      })
      .catch((err) => {
        console.log(err);
        setAlertMessage("Something went wrong, please try again");
        setErrorAlert(true);
        setTimeout(() => {
          setErrorAlert(false);
          setAlertMessage("");
          document.getElementById("img_submit").style.display = "flex";
        }, 3000);
      });
  };

  return (
    <main className="images__page">
      <h2>Please attach the images for your EOD</h2>
      {message == "" ? (
        <>
          <div className="image__selection">
            <select
              value={type}
              onChange={(e) => {
                changeType(e.target.value);
              }}
            >
              <option>--- SELECT PAYOUT TYPE ---</option>
              <option>LOTTERY</option>
              <option>KEY FUELS</option>
              <option>PAYPOINT</option>
              <option>OTHER</option>
            </select>
            {errorAlert ? (
              <Alert
                severity="warning"
                sx={{ width: "80vw", margin: "2% auto" }}
              >
                {alertMessage}
              </Alert>
            ) : (
              <></>
            )}
            <input
              type="file"
              accept="image/*"
              value={image}
              onChange={(e) => {
                addImage(e.target.files[0]);
              }}
            />
          </div>
          {images.length >= 1 ? (
            <div className="show__images">
              <table>
                <thead>
                  <th>Payout Type</th>
                  <th>Image</th>
                </thead>
                <tbody>
                  {images.map(function (d, i) {
                    return (
                      <tr>
                        <td>{d.type}</td>
                        <td>
                          {d.type == "NEWSPAPER COUPON" ? (
                            <p>{d.image}</p>
                          ) : (
                            <img src={d.image} width="200px" style={{}} />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {showLoader ? <NitroLoader /> : <></>}
              {errorAlert ? (
                <Alert severity="warning">{alertMessage}</Alert>
              ) : (
                <></>
              )}
              <Button
                color="success"
                variant="outlined"
                onClick={() => {
                  submit();
                }}
                sx={{ margin: "2% auto" }}
                id="img_submit"
              >
                Submit
              </Button>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <h2>{message}</h2>
      )}
    </main>
  );
}
