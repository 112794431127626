import StoreNav from "./StoreNav";
// import "./styles/staffHome.css";
import "./styles/storeZone.scss";
import Footer from "../Footer";
import UserContext from "../context/userContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import { getAllStores } from "../utils/Api";

export default function StoreZone() {
    const navigate = useNavigate();
    const { userData } = useContext(UserContext);
    const [siteList, setSiteList] = useState([]);
    const [site, setSite] = useState("");
    const [password, setPassword] = useState("");
    const [loginAlert, setLoginAlert] = useState(false);

    useEffect(() => {
        getAllStores()
            .then((data) => {
                setSiteList(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleSite = (site) => {
        setSite(site);
        siteList.forEach((store) => {
           
            if (store.siteName === site) {
                localStorage.setItem("siteID", store.eposId);
            }
        });
    };

    const login = async () => {
        axios
            .post(
                `https://nitropos-backend.nitrosolutions.co.uk/storezone/${site}`,
                {
                    password: password,
                }
            )
            .then((res) => {
                if (res.data) {
                    localStorage.setItem("site", JSON.stringify(site));
                    navigate("/storezone/home");
                    // console.log(site)
                } else {
                    setLoginAlert(true);
                    setTimeout(() => {
                        setLoginAlert(false);
                    }, 2500);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            login();
        }
    };

    return (
        <>
            {/* {userData.user ? ( */}
            <div className="StaffHome">
                <h1>Sign In To Access The Store Zone</h1>
                {loginAlert ? (
                    <Alert
                        severity="error"
                        sx={{
                            width: "80%",
                            margin: "auto",
                            marginBottom: "2%",
                        }}
                    >
                        Incorrect Credentials! Please Try Again.
                    </Alert>
                ) : (
                    <></>
                )}
                <div className="login">
                    <select
                        value={site}
                        // onChange={(e) => { setSite(e.target.value) }}
                        onChange={(e) => {
                            handleSite(e.target.value);
                        }}
                    >
                        <option>--- Select Store ---</option>
                        {siteList.map(function (d, i) {
                            return (
                                <option value={d.siteName}>{d.siteName}</option>
                            );
                        })}
                    </select>
                    <br />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter The Store Password..."
                        onKeyDown={handleKeyDown}
                    />
                    <br />
                    <button
                        onClick={() => {
                            login();
                        }}
                    >
                        Login
                    </button>
                </div>
                <Footer />
            </div>
            {/* ) : (
        <>{navigate("/")}</>
      )} */}
        </>
    );
}
