import Footer from "../Footer";
import "./styles/Orders.scss";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import axios from "axios";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import Alert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import StoreNav from "./StoreNav";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ModalClaims from "./ModalClaim";
import Loader from "../animations/Loader";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    "@media(max-width: 600px)": {
        width: "90vw",
        maxHeight: "40vh",
    },
    "@media(min-width: 601px) and (max-width: 900px)": {
        width: "45vw",
        maxHeight: "40vh",
    },
};

const discrep_modal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "1rem",
    boxShadow: 24,
    p: 4,
    maxHeight: "60vh",
    overflowY: "scroll",
    "@media(max-width: 600px)": {
        width: "90vw",
        maxHeight: "40vh",
    },
    "@media(min-width: 601px) and (max-width: 900px)": {
        width: "45vw",
        maxHeight: "40vh",
    },
};

export default function Order() {
    const [data, setData] = useState([]);
    const [showSumary, setShowSumary] = useState(true);
    const [staffName, setStaffName] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [processed, setProcessed] = useState(false);
    const [failAlert, setFailAlert] = useState(false);
    const [store, setStore] = useState("");
    const [note, setNote] = useState("N/A");
    const [message, setMessage] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const handleOpen = () => setShowConfirm(true);
    const handleClose = () => setShowConfirm(false);
    const [showSubmitBtn, setShowSubmitBtn] = useState(true);
    const [imgLabel, setImglabel] = useState(
        "Please take a photo of the invoice"
    );
    const [images, setImages] = useState([]);
    const url = "https://nitropos-backend.nitrosolutions.co.uk";
    const [showLoader, setShowLoader] = useState(true);
    const [boxes, setBoxes] = useState(0);
    const [boxesConfirm, setBoxesConfirm] = useState(0);
    const [v4u, setV4u] = useState(false);
    const [boxesAlert, setBoxesAlert] = useState(false);
    //array for products to claim
    const [claims, setClaims] = useState([]);

    const [openClaims, setOpenClaims] = useState(false);
    const handleOpenClaims = () => setOpenClaims(true);
    const handleCloseClaims = () => setOpenClaims(false);

    const [openVapeUpload, setOpenVapeUpload] = useState(false);
    const [vapeAlert, setVapeAlert] = useState(false);
    const handleOpenVape = () => setOpenVapeUpload(true);
    const handleCloseVape = () => setOpenVapeUpload(false);

    const [openDiscrep, setOpenDiscrep] = useState(false);
    const handleOpenDiscrep = () => setOpenDiscrep(true);
    const handleCloseDiscrep = () => setOpenDiscrep(false);
    const [discreps, setDiscreps] = useState([]);

    //testing array
    const categories = [
        "Retail Grocery",
        "Chilled",
        "Frozen Food",
        "Confectionery",
        "Wines Spirits Beers",
        "Meat",
        "Fruit & Veg",
        "Non-Food",
        "Catering Grocery",
    ];

    useEffect(async () => {
        let i = localStorage.getItem("invoice");
        await axios
            .get(`${url}/edn/invoice?invoiceNumber=${JSON.parse(i)}`)
            .then((res) => {
                console.log(res.data);
                if (res.data.supplier === "V4U Disposables") {
                    setBoxes(res.data.boxes);
                    setV4u(true);
                }
                setData(res.data);
                setStore(res.data.site);
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(`Could not get details: ${err}`);
                setMessage(`Could not get invoice details: ${err}`);
                setShowLoader(false);
                setFailAlert(true);
            });
    }, []);

    function ImageHandle(image) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(image);
        fileReader.onload = () => {
            setImages((oldArray) => [...oldArray, fileReader.result]);
            setImglabel("Add Another Image If Required.");
        };
    }

    //checks the submission
    const submitClaims = async (array) => {
        // var processClaim = false;
        // array.forEach(claim => {
        //   if (!claim.reason) {
        //     alert("Please select a reason")
        //   } else if (claim.reason === "Damaged" && (claim.inStock === 0 || !claim.inStock)) {
        //     alert("Please select how much stock you are retaining")
        //   } else if (claim.reason === "Damaged" && !claim.image) {
        //     alert("Please insert an image")
        //   } else {
        //     processClaim = true;
        //   }
        // });
        // if (processClaim) {
        //   let claim = {
        //     site: data.eposId,
        //     id: data._id,
        //     invoiceNumber: data.invoiceNumber,
        //     claims: array,
        //     supplier: data.supplier
        //   }
        //   //call to back end to send the array
        //   await axios.post("https://nitropos-backend.nitrosolutions.co.uk/claims/ednClaim/submit", claim).then((res) => {
        //     console.log(res.data)
        //   }).catch((err) => {
        //     console.log(err)
        //   })
        //   setOpenClaims(false)
        //   setClaims([])
        //   //Submit()
        // }
    };

    function Submit() {
        if (claims.length > 0) {
            handleOpenClaims();
        } else if (discreps.length > 0) {
            handleOpenDiscrep();
        } else {
            console.log("processing");
            if (
                images.length > 0 ||
                data.supplier == "Booker" ||
                data.supplier == "Londis" ||
                data.supplier == "Tubbees Bakes" ||
                data.supplier == "Warehouse"
            ) {
                console.log("Submit To DB");
                var details = {
                    invoiceNumber: data.invoiceNumber,
                    staffName: staffName,
                    productsOrdered: data.productsOrdered,
                    site: data.site,
                    images: images,
                    note: note,
                };
                // console.log(details);
                setShowSumary(false);
                setLoading(true);
                setShowSubmitBtn(false);
                axios
                    .post(`${url}/edn/submit`, details)
                    .then((res) => {
                        setLoading(false);
                        setProcessed(true);
                        setShowSubmitBtn(true);
                    })
                    .catch((err) => {
                        setLoading(false);
                        setShowSumary(true);
                        setMessage("Something went wrong, please try again");
                        setFailAlert(true);
                        setTimeout(() => {
                            setFailAlert(false);
                            setShowSubmitBtn(true);
                        }, 2500);
                    });
            } else {
                handleOpen(true);
                setMessage(
                    "An Image Of The Invoice/Delivery Note Must Be Attached!"
                );
                setFailAlert(true);
                setTimeout(() => {
                    setFailAlert(false);
                }, 2500);
            }
        }
    }

    function confirmSubmit(added) {
        if (!added) {
            handleClose(false);
        } else {
            // console.log("SUBMIT DB");
            handleClose(false);
            var details = {
                invoiceNumber: data.invoiceNumber,
                staffName: staffName,
                productsOrdered: data.productsOrdered,
                site: data.site,
                images: images,
                note: note,
            };
            console.log(details);
            setShowSumary(false);
            setLoading(true);
            setShowSubmitBtn(false);
            axios
                .post(`${url}/edn/submit`, details)
                .then((res) => {
                    // console.log(res.data);
                    setLoading(false);
                    setProcessed(true);
                    setShowSubmitBtn(true);
                })
                .catch((err) => {
                    setLoading(false);
                    setShowSumary(true);
                    setMessage("Something went wrong, please try again");
                    setFailAlert(true);
                    setTimeout(() => {
                        setFailAlert(false);
                        setShowSubmitBtn(true);
                    }, 2500);
                });
        }
    }

    function handleAlert() {
        setFailAlert(false);
    }

    function handleChangedQty(newQ, index) {
        let newQty = [...data.productsOrdered];
        newQty[index] = { ...newQty[index], qtyReceived: parseInt(newQ) };

        var newArray = data;
        newArray.productsOrdered = newQty;

        //checks if the qty is different
        if (
            data.productsOrdered[index].caseQty !=
            newArray.productsOrdered[index].qtyReceived
        ) {
            let checkIndex = discreps.findIndex(
                (p) => p.name === data.productsOrdered[index].Name
            );

            if (checkIndex < 0) {
                setDiscreps((discrepancy) => [
                    ...discrepancy,
                    {
                        name: data.productsOrdered[index].Name,
                        caseQty: data.productsOrdered[index].caseQty,
                        received: parseInt(newQ),
                    },
                ]);
            } else {
                discreps[checkIndex].received = parseInt(newQ);
            }
            console.log(discreps);
        } else {
            //checks if the item is on the array, and deletes it if the qty is the same
            let checkIndex = discreps.findIndex(
                (p) => p.name === data.productsOrdered[index].Name
            );

            if (checkIndex >= 0) {
                discreps.splice(checkIndex, 1);
            }
        }

        //adds product to the claims array
        // if (data.productsOrdered[index].qtyOrdered > (newArray.productsOrdered[index].qtyReceived * newArray.productsOrdered[index].packQty)) {
        //   var claimData = {
        //     barcode: data.productsOrdered[index].Barcode,
        //     name: data.productsOrdered[index].Name,
        //     ordered: data.productsOrdered[index].qtyOrdered,
        //     toClaim: data.productsOrdered[index].qtyOrdered - newQ * data.productsOrdered[index].packQty,
        //     packageQty: data.productsOrdered[index].packQty,
        //     sku: data.productsOrdered[index].Sku,
        //     invoiceCaseQty: data.productsOrdered[index].qtyOrdered,
        //     invoiceUnitQty: data.productsOrdered[index].packQty * data.productsOrdered[index].qtyOrdered,
        //     idx: index
        //   };
        //   setClaims((claim) => [...claim, claimData])
        // }
    }

    const confirmDiscreps = async () => {
        handleCloseDiscrep();
        setDiscreps([]);
        //submits the products if confirmed
        if (data.supplier == "V4U E-Liquids & Kits" && images.length == 0) {
            //add invoice to submission
            handleOpenVape();
            setMessage(
                "An Image Of The Invoice/Delivery Note Must Be Attached!"
            );
            setFailAlert(true);
            setTimeout(() => {
                setFailAlert(false);
            }, 2500);
        } else {
            var details = {
                invoiceNumber: data.invoiceNumber,
                staffName: staffName,
                productsOrdered: data.productsOrdered,
                site: data.site,
                images: images,
                note: note,
            };
            setShowSumary(false);
            setLoading(true);
            setShowSubmitBtn(false);
            axios
                .post(`${url}/edn/submit`, details)
                .then((res) => {
                    setLoading(false);
                    setProcessed(true);
                    setShowSubmitBtn(true);
                })
                .catch((err) => {
                    setLoading(false);
                    setShowSumary(true);
                    setMessage("Something went wrong, please try again");
                    setFailAlert(true);
                    setTimeout(() => {
                        setFailAlert(false);
                        setShowSubmitBtn(true);
                    }, 2500);
                });
        }
    };

    const confirmBoxes = () => {
        if (boxesConfirm === 0) {
            setMessage("Please insert amount of delivery boxes");
            setBoxesAlert(true);
            setTimeout(() => {
                setBoxesAlert(false);
            }, 3000);
        } else if (boxesConfirm != boxes) {
            setMessage("Boxes do not match, an email is being sent");
            setBoxesAlert(true);
            const emailData = {
                invoiceNumber: data.invoiceNumber,
                site: data.site,
                boxes: boxes,
                boxesConfirmed: boxesConfirm,
            };
            axios
                .post(`${url}/edn/boxes-error`, emailData)
                .then((res) => {
                    setBoxesAlert(false);
                    setV4u(false);
                })
                .catch((err) => {
                    console.log(`Could not send the error alert: ${err}`);
                });
        } else {
            setV4u(false);
        }
    };

    const vapeConfirm = async () => {
        if (images.length > 0) {
            var details = {
                invoiceNumber: data.invoiceNumber,
                staffName: staffName,
                productsOrdered: data.productsOrdered,
                site: data.site,
                images: images,
                note: note,
            };
            console.log(details);
            handleCloseVape();
            setLoading(true);
            setShowSubmitBtn(false);

            setTimeout(() => {
                setLoading(false);
                setProcessed(true);
                setShowSubmitBtn(true);
            }, 5000);
            axios
                .post(`${url}/edn/submit`, details)
                .then((res) => {
                    setLoading(false);
                    setProcessed(true);
                    setShowSubmitBtn(true);
                })
                .catch((err) => {
                    setLoading(false);
                    handleOpenVape(true);
                    setMessage("Something went wrong, please try again");
                    setVapeAlert(true);
                    setTimeout(() => {
                        setVapeAlert(false);
                        setShowSubmitBtn(true);
                    }, 2500);
                });
        } else {
            setMessage("Please Attach Invoice!");
            setVapeAlert(true);
            setTimeout(() => {
                setVapeAlert(false);
            }, 2500);
        }
    };

    return (
        <section className="orderContent">
            <StoreNav site={store} />

            {failAlert ? (
                <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
                    <Stack direction="row" spacing={2}>
                        {message}
                        <CloseIcon
                            onClick={() => handleAlert()}
                            sx={{ color: "#fff", margin: "0" }}
                            fontSize="small"
                        />
                    </Stack>
                </Alert>
            ) : null}
            {showLoader ? (
                <div style={{ minHeight: "74vh" }}>
                    <h1>We are getting your invoice, please wait</h1>
                    <Loader />
                </div>
            ) : (
                <>
                    {v4u ? (
                        <section className="boxes">
                            <h3>
                                Please confirm how many boxes this delivery came
                                in
                            </h3>
                            <input
                                type="number"
                                pattern="[0-9]*"
                                onChange={(e) =>
                                    setBoxesConfirm(parseInt(e.target.value))
                                }
                            />
                            {boxesAlert ? (
                                <Alert
                                    severity="warning"
                                    variant="filled"
                                    sx={{ width: "60%" }}
                                >
                                    {message}
                                </Alert>
                            ) : (
                                <Button
                                    color="success"
                                    variant="outlined"
                                    onClick={confirmBoxes}
                                >
                                    Continue
                                </Button>
                            )}
                        </section>
                    ) : (
                        <>
                            {data.supplier ? (
                                <div className="orderContent">
                                    <h2>Please check your invoice</h2>
                                    <div className="head">
                                        <h4>{data.invoiceNumber}</h4>
                                        <h4>{data.site}</h4>
                                        <h4> {data.invoiceDate}</h4>
                                    </div>
                                    <table id="invoice">
                                        <tbody>
                                            <tr className="table_header">
                                                <th>Product Name</th>
                                                <th>Quantity Ordered</th>
                                                <th>Quantity Received</th>
                                                <th>Current Stock</th>
                                            </tr>
                                            {data.productsOrdered
                                                ? data.productsOrdered.map(
                                                      function (d, i) {
                                                          var warn;
                                                          d.caseQty !==
                                                          d.qtyReceived
                                                              ? (warn =
                                                                    "orange")
                                                              : (warn = "");
                                                          return (
                                                              <tr
                                                                  style={{
                                                                      backgroundColor:
                                                                          warn,
                                                                  }}
                                                              >
                                                                  <td>
                                                                      {d.Name}
                                                                  </td>
                                                                  <td>
                                                                      {
                                                                          d.caseQty
                                                                      }
                                                                  </td>
                                                                  <td>
                                                                      <input
                                                                          type="number"
                                                                          placeHolder={
                                                                              d.qtyReceived
                                                                          }
                                                                          className="checkInput"
                                                                          onChange={(
                                                                              e
                                                                          ) => {
                                                                              handleChangedQty(
                                                                                  e
                                                                                      .target
                                                                                      .value,
                                                                                  i
                                                                              );
                                                                          }}
                                                                      />
                                                                  </td>
                                                                  <td>
                                                                      {
                                                                          d.currentStock
                                                                      }
                                                                  </td>
                                                              </tr>
                                                          );
                                                      }
                                                  )
                                                : null}
                                        </tbody>
                                    </table>
                                    <input
                                        type="text"
                                        placeHolder="Please insert your Name"
                                        className="staffName"
                                        onChange={(e) =>
                                            setStaffName(e.target.value)
                                        }
                                    />
                                    <textarea
                                        className="note_section"
                                        onChange={(e) =>
                                            setNote(e.target.value)
                                        }
                                        placeholder="Please Insert a Note if there is a discrepancy"
                                    />
                                    {data.supplier === "Booker" ||
                                    data.supplier === "Londis" ||
                                    data.supplier === "Tubbees Bakes" ||
                                    data.supplier == "Warehouse" ? (
                                        <></>
                                    ) : (
                                        <>
                                            <label
                                                style={{ textAlign: "center" }}
                                            >
                                                {imgLabel}
                                            </label>
                                            <input
                                                type="file"
                                                accepts="image/*"
                                                onChange={(e) => {
                                                    ImageHandle(
                                                        e.target.files[0]
                                                    );
                                                }}
                                            />
                                            <br />
                                        </>
                                    )}
                                    {images.map(function (d, i) {
                                        return (
                                            <img
                                                style={{ margin: "0 auto" }}
                                                src={d}
                                                alt="Invoice Image"
                                                width="500px"
                                            />
                                        );
                                    })}
                                    {showSubmitBtn ? (
                                        <Button
                                            variant="contained"
                                            endIcon={
                                                <SendIcon
                                                    sx={{ margin: "0" }}
                                                />
                                            }
                                            onClick={() => Submit()}
                                            className="final_submit_btn"
                                        >
                                            Submit
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            ) : null}
                        </>
                    )}
                    {/* {data.supplier ? (
            <div className="orderContent">
              <h2>Please check your invoice</h2>
              <div className="head">
                <h4>{data.invoiceNumber}</h4>
                <h4>{data.site}</h4>
                <h4> {data.invoiceDate}</h4>
              </div>
              <table id="invoice">
                <tbody>
                  <tr>
                    <th>Product Name</th>
                    <th>Quantity Ordered</th>
                    <th>Quantity Received</th>
                    <th>Current Stock</th>
                  </tr>
                  {data.productsOrdered ? (
                    data.productsOrdered.map(function (d, i) {
                      var warn;
                      d.caseQty !== d.qtyReceived
                        ? (warn = "orange")
                        : (warn = "");
                      return (
                        <tr style={{ backgroundColor: warn }}>
                          <td>{d.Name}</td>
                          <td>{d.caseQty}</td>
                          <td>
                            <input
                              type="number"
                              placeHolder={d.qtyReceived}
                              className="checkInput"
                              onChange={(e) => {
                                handleChangedQty(e.target.value, i);
                              }}
                            />
                          </td>
                          <td>{d.currentStock}</td>
                        </tr>
                      );
                    })
                  ) : null}
                </tbody>
              </table>
              <input
                type="text"
                placeHolder="Please insert your Name"
                className="staffName"
                onChange={(e) => setStaffName(e.target.value)}
              />
              <textarea
                cols={50}
                rows={10}
                onChange={(e) => setNote(e.target.value)}
                placeholder="Please Insert a Note if there is a discrepancy"
              />
              {data.supplier === "Booker" ||
                data.supplier === "Londis" ||
                data.supplier === "Tubbees Bakes" ? (
                <></>
              ) : (
                <>
                  <label style={{ textAlign: "center" }}>{imgLabel}</label>
                  <input
                    type="file"
                    accepts="image/*"
                    onChange={(e) => {
                      ImageHandle(e.target.files[0]);
                    }}
                  />
                  <br />
                </>
              )}
              {images.map(function (d, i) {
                return (
                  <img
                    style={{ margin: "0 auto" }}
                    src={d}
                    alt="Invoice Image"
                    width="500px"
                  />
                );
              })}
              {showSubmitBtn ?
                <Button
                  variant="outlined"
                  color="error"
                  endIcon={<SendIcon sx={{ margin: "0" }} />}
                  onClick={() => Submit()}
                  style={{ width: "20%", margin: "auto" }}
                >
                  Submit
                </Button>
                :
                <></>}

            </div>
          ) : null} */}
                </>
            )}
            {loading ? (
                <div className="loadingSection">
                    <h4>Your Invoice is being processed, please wait</h4>
                    <FadeLoader color="#2c53a0" loading={loading} size={500} />
                </div>
            ) : null}
            {processed ? (
                <div className="processed">
                    <h4>Invoice has been processed</h4>
                    <Button
                        variant="outlined"
                        sx={{ width: "60%" }}
                        color="primary"
                        onClick={navigate(`/storezone/home?site=${data.site}`)}
                    >
                        Back to Invoices
                    </Button>
                </div>
            ) : null}
            <Modal
                open={showConfirm}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Are you sure you want to submit without an image?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Stack
                            spacing={2}
                            direction="row"
                            sx={{ width: "70%", margin: "auto" }}
                        >
                            {showSubmitBtn ? (
                                <>
                                    {" "}
                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={() => {
                                            confirmSubmit(true);
                                        }}
                                    >
                                        Submit
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={() => {
                                            confirmSubmit(false);
                                        }}
                                    >
                                        Add image
                                    </Button>
                                </>
                            ) : (
                                <></>
                            )}
                        </Stack>
                    </Typography>
                </Box>
            </Modal>

            <Modal
                open={openVapeUpload}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        As there is a discrepancy, you must upload an image of
                        the invoice?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Stack
                            spacing={2}
                            direction="row"
                            sx={{ width: "70%", margin: "auto" }}
                        >
                            {images.length > 0 ? (
                                <Button
                                    variant="outlined"
                                    color="error"
                                    endIcon={<SendIcon sx={{ margin: "0" }} />}
                                    onClick={() => {
                                        vapeConfirm();
                                    }}
                                >
                                    Submit
                                </Button>
                            ) : (
                                <input
                                    type="file"
                                    accepts="image/*"
                                    onChange={(e) => {
                                        ImageHandle(e.target.files[0]);
                                    }}
                                />
                            )}

                            {vapeAlert ? (
                                <Alert severity="warning" variant="filled">
                                    {message}
                                </Alert>
                            ) : (
                                <></>
                            )}
                        </Stack>
                    </Typography>
                </Box>
            </Modal>

            <Modal
                open={openDiscrep}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={discrep_modal}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Please double check the following items received
                        quantities
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div className="discrepancy_modal">
                            <table className="discrepancy_table">
                                <thead>
                                    <tr>
                                        <td>Product</td>
                                        <td>Case Qty Ordered</td>
                                        <td>Case Qty Received</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {discreps.map((discrepancy, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{discrepancy.name}</td>
                                                <td>{discrepancy.caseQty}</td>
                                                <td>{discrepancy.received}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div className="discrep_modal_row">
                                <Button
                                    onClick={handleCloseDiscrep}
                                    variant="contained"
                                    color="error"
                                >
                                    Close
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="success"
                                    onClick={confirmDiscreps}
                                >
                                    Confirm
                                </Button>
                            </div>
                        </div>
                    </Typography>
                </Box>
            </Modal>
            <Modal
                open={openClaims}
                onClose={handleCloseClaims}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalClaims claims={claims} submitClaims={submitClaims} />
            </Modal>
            <Footer />
        </section>
    );
}
