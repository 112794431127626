//React
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
//Components
import NavBar from "../NavBar";
import StorePurchaseOrder from "../StoreZone/productManagement/purchase_order/StorePurchaseOrder";
import Loader from "../animations/Loader";
//Mui
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import { Alert, Button } from "@mui/material";

export default function PurchaseOrderApproval() {
    //Url
    const url = process.env.REACT_APP_DEPPLOYED;
    //Params
    const { id } = useParams();
    //Purchase Order
    const [po, setPo] = useState([]);
    const [headerInfo, setHeaderInfo] = useState<any>({});
    //Alerts
    const [alert, setAlert] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [severity, setSeverity] = useState<any>("");
    const [disable, setDisable] = useState<boolean>(false);
    //Loader
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        axios
            .get(`${url}/purchaseOrder/GetPoById/${id}`)
            .then((res) => {
                setPo(res.data);
                setHeaderInfo(res.data);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                setLoader(false);
                handleAlert("Something went wrong - Please try again", "error");
            });
    }, []);

    //Alerts
    const handleAlert = (
        message: string,
        severity: string,
        duration: number = 4000
    ): void => {
        setDisable(true);
        setMessage(message);
        setSeverity(severity);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setSeverity("");
            setDisable(false);
        }, duration);
    };
    return (
        <main className="purchase_orders_approval">
            {" "}
            <Box>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        padding: "0",
                    }}
                >
                    <Toolbar
                        sx={{ width: "100vw", backgroundColor: "#2c53a0" }}
                    >
                        <NavBar />
                    </Toolbar>
                </AppBar>
            </Box>
            <section className="po_approval_main">
                <h1>Purchase Order ID:{id} Approval</h1>
                {headerInfo.stockTakeId ? (
                    <Button
                        className="stock_take_btn"
                        variant="contained"
                        color="success"
                        onClick={() => {
                            window.open(
                                `https://www.eposnowhq.com/Pages/BackOffice/StockTakeDetails.aspx?TransferID=${headerInfo.stockTakeId}&Print=false`,
                                "_blank"
                            );
                        }}
                    >
                        Stock Take
                    </Button>
                ) : (
                    <Button
                        className="stock_take_btn"
                        variant="contained"
                        color="error"
                    >
                        Stock Take - TBC
                    </Button>
                )}
                {!loader ? (
                    <>
                        <section className="po_header_details">
                            <p>
                                <span>Site: </span>
                                {headerInfo && headerInfo.siteName}
                            </p>
                            <p>
                                <span>Supplier: </span>{" "}
                                {headerInfo && headerInfo.supplierName}
                            </p>
                            <p>
                                <span>Status: </span>{" "}
                                {headerInfo && headerInfo.statusName}
                            </p>
                            <p>
                                <span>Submitted: </span>{" "}
                                {moment(headerInfo.submissionDate).format(
                                    "DD-MM-YYYY HH:mm"
                                )}
                            </p>
                        </section>
                        <StorePurchaseOrder approvalPo={po} />
                        <section className="po_approval_btns"></section>
                    </>
                ) : (
                    <Loader />
                )}
            </section>
            {/* DIALOGS */}
            <Dialog open={alert} className="po_alert_dialog">
                <Alert className="alert" severity={severity}>
                    {message}
                </Alert>
            </Dialog>
        </main>
    );
}
