import "./invoices.scss";
import { useEffect, useState, Component, Fragment } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NavBar from "../NavBar";
import Footer from "../Footer";
import LoginTest from "../LoginTest";
import Clyde from "./Clyde";
import Pending from "./tabs/Pending";
import Open from "./tabs/Open";
import Closed from "./tabs/Closed";

import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import GppBadIcon from "@mui/icons-material/GppBad";
import VapingRoomsIcon from "@mui/icons-material/VapingRooms";
import IndvInvoice from "./tabs/IndvInvoice";

const drawerWidth = "10vw";
//errorboundary for error handling
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
          <h3>An Alert has been sent to IT</h3>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default function InvoicesHome(props) {
  const [role, setRole] = useState("");
  const [open, setOpen] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [invoice, setInvoice] = useState({});
  const [value, setValue] = useState(0);

  

  useEffect(() => {
    let role = localStorage.getItem("role");
    setRole(role);
  }, []);

  const handleChange = (index) => {
    setValue(index );
  }


  const handleIndvInvoice = (data) => {
    setOpen(false);
    setShowInvoice(true);
    setInvoice(data);
  };

  return (
    <>
      {role === "Manager" || role === "Admin" || role === "Developer" || role === "HR" ? (
        <main className="dashboard">
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
              position="fixed"
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, padding: "0" }}
            >
              <Toolbar>
                <NavBar />
              </Toolbar>
            </AppBar>
            <Drawer
              variant="permanent"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  width: drawerWidth,
                  boxSizing: "border-box",
                },
              }}
            >
              <Toolbar />
              <Box sx={{ overflow: "auto", marginTop: "12vh" }}>
                <List>
                  {["Pending", "Open", "Closed", "Clyde"].map((text, index) => (
                    <ListItem
                      key={text}
                      disablePadding
                      onClick={() => handleChange(index)}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          {index === 0 ? (
                            <PendingActionsIcon sx={{ margin: "0" }} />
                          ) : index === 1 ? (
                            <FileOpenIcon sx={{ margin: "0" }} />
                          ) : index === 2 ? (
                            <GppBadIcon sx={{ margin: "0" }} />
                          ) : index === 3 ? (
                            <VapingRoomsIcon sx={{ margin: "0" }} />
                          ) : (
                            <></>
                          )}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: "10vh" }}>
              <Toolbar />
              {value === 0 ? (
                <ErrorBoundary>
                  <Pending />
                </ErrorBoundary>
              ) : value === 1 ? (
                <ErrorBoundary>
                  <Open handle={handleIndvInvoice} />
                </ErrorBoundary>
              ) : value === 2 ? (
                <ErrorBoundary>
                  <Closed />
                </ErrorBoundary>
              ) : (value === 3) && (role === "Manager" || role === "Developer") ? (
                <ErrorBoundary>
                  <Clyde />
                </ErrorBoundary>
              ) : value === 4 ? (
                <ErrorBoundary>
                  <IndvInvoice invoice={invoice} />
                </ErrorBoundary>
              ) : (
                <h1>You don't have permissions for this</h1>
              )}
            </Box>
          </Box>

          <Footer />
        </main>
      ) : (
        <LoginTest />
      )}
    </>
  );
}
