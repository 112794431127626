import { useEffect, useState } from "react";
import DevNav from "../devZone/nav/DevNav";
import ManagerNav from "../managerZone/ManagerNav";
import WareNav from "../warehouse/WareNav";
import FtgNav from "../FTG/FtgNav";
import NavBar from "../NavBar";
import Footer from "../Footer";

export default function NotAllowed() {
  const [user, setUser] = useState("");

  useEffect(() => {
    let userD = localStorage.getItem("userDetails");
    if ((userD === "")) {
      setUser("");
    } else {
      let user = JSON.parse(userD);
      setUser(user.user.role);
    }
  }, []);

  return (
    <main style={{ height: "100vh" }}>
      {user === "Developer" ? (
        <DevNav />
      ) : user === "Store Manager" && user.user.role === "Supervisor" ? (
        <ManagerNav />
      ) : user === "V4U Manager" &&
        user === "Warehouse Manager" &&
        user.user.role === "Warehouse Packer" && user.user.role === "" ? (
        <WareNav />
      ) : user === "Food Manager" ? (
        <FtgNav />
      ) : (
        <NavBar />
      )}
      <section style={{ maxHeight: "70vh" }}>
        <h1>
          You are not allowed to see this section, please navigate to a section
          you are allowed in
        </h1>
      </section>
      <Footer />
    </main>
  );
}
