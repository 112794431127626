import React, { useState, useEffect, useContext, Component } from "react";
import NavBar from "../NavBar";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Footer from "../Footer";
import UserContext from "../context/userContext";
import Discrepancies from "./Discrepancies";
import "./investigations/investigation.scss";
import DiscrepanciesRep from "./DiscrepanciesRep";
import ManualDisc from "./ManualDisc";
import ErrorBoundary from "../error/ErrorBoundary";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


export default function DiscrepancyHome(props) {
  const [value, setValue] = useState(0);
  const { userData } = useContext(UserContext);
  const [user, setUser] = useState("");

  useEffect(() => {
    let role = localStorage.getItem("role");
    let userD = localStorage.getItem("userDetails");
    let user = JSON.parse(userD);

    setUser(role);
  }, []);

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <main className="discrepancy__investigation">
      <NavBar  />
      <Box sx={{ width: "100%", marginLeft: "1%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            <Tab label="Investigation" {...a11yProps(0)} />
            <Tab label="EOD Report" {...a11yProps(1)} />
            <Tab label="Manual Investigation" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <ErrorBoundary>
            <Discrepancies />
          </ErrorBoundary>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ErrorBoundary>
            <DiscrepanciesRep />
          </ErrorBoundary>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ErrorBoundary>
            <ManualDisc />
          </ErrorBoundary>
        </TabPanel>
      </Box>
      <Footer />
    </main>
  );
}
